import React, { useState, useEffect, useRef } from 'react';
import './MobileCurrencyComponent.scss';
import Tooltip from '@mui/material/Tooltip';
import UpdateIcon from '@mui/icons-material/Update';

const MobileCurrencyComponent = ({selectedValue, currentPrice, todaysArticleCount, yesterdaysArticleCount, twentyFourHoursChange, todaysAvgTone, yesterdaysAvgTone, newArticlesCountdown}) => {
    const [articleUpdateCountdown, setArticleUpdateCountdown] = useState(0); // In Sekunden

    useEffect(() => {
      const [minutes, seconds] = newArticlesCountdown.split(':');
      const totalSeconds = parseInt(minutes) * 60 + parseInt(seconds);
      setArticleUpdateCountdown(totalSeconds); // Umwandlung in Sekunden
      const interval = setInterval(() => {
        setArticleUpdateCountdown(prevCountdown => prevCountdown - 1);
      }, 1000); // Aktualisiere jede Sekunde
  
      return () => {
        clearInterval(interval);
      };
    }, [newArticlesCountdown]);
    
  /**
   * 
   * @param {number} amountOld The old value
   * @param {number} amountNew 
   * @returns 
   */
    const calculatePercentageChange = (amountOld, amountNew) => {
        const percentageChange = ((amountNew / amountOld) - 1) * 100;
        return percentageChange.toFixed(1);
    };

    return (

        <div className='mobile-currency-container'>
            <label><strong>{selectedValue.toUpperCase()}</strong></label>
            <div className='mobile-description-container'>
                <div>
                    <div style={{ marginTop: '20px' }}></div>
                    {todaysArticleCount !== null && (
                        <div>
                            Todays news count: {todaysArticleCount}{" "}
                            <span
                                style={{
                                    color: calculatePercentageChange(yesterdaysArticleCount, todaysArticleCount) < 0 ? "#ff6961" : "#61ff69",
                                }}
                            >
                                {calculatePercentageChange(yesterdaysArticleCount, todaysArticleCount) < 0 ? "↓" : "↑+"}
                                {calculatePercentageChange(yesterdaysArticleCount, todaysArticleCount)}%
                            </span>
                        </div>
                    )}
                    <div style={{ marginTop: '10px' }}></div>
                    <div>
                        Price: {currentPrice}${" "}
                        <span
                            style={{
                                color: twentyFourHoursChange < 0 ? "#ff6961" : "#61ff69",
                            }}
                        >
                            {twentyFourHoursChange < 0 ? "↓" : "↑+"}
                            {twentyFourHoursChange ? twentyFourHoursChange.toFixed(1) : "-"}%
                        </span>
                    </div>
                    <div style={{ marginTop: '10px' }}></div>
                    <div>
                        Average Tone:  {todaysAvgTone}{" "}
                        <span
                            style={{
                                color: (todaysAvgTone - yesterdaysAvgTone) < 0 ? "#ff6961" : "#61ff69",
                            }}
                        >
                            {(todaysAvgTone - yesterdaysAvgTone) < 0 ? "↓" : "↑+"}
                            {(todaysAvgTone - yesterdaysAvgTone).toFixed(2)}
                        </span>
                    </div>
                </div>
                <div>
                    <Tooltip title="Time until new Auto-Update">
                        <div className='mobile-countdown-icon-container'>
                        <UpdateIcon/>
                        </div>
                    </Tooltip>{" "}
                    <div className='mobile-countdown-container'>
                        {articleUpdateCountdown >= 0 ? `${Math.floor(articleUpdateCountdown / 60)}:${(articleUpdateCountdown % 60).toString().padStart(2, '0')}` : "0:00"}
                    </div>
                </div>
            </div>
        </div>
    );

};



export default MobileCurrencyComponent;
