import React, { useState, useEffect, useRef } from 'react';
import LanguageIcon from '@mui/icons-material/Language';
import LinkIcon from '@mui/icons-material/Link';
import ScheduleIcon from '@mui/icons-material/Schedule';
import {Button, Grid} from '@mui/material';
import './FooterComponent.scss';
import DateConverter from './DateConverter';
import detectAndTranslate from './utils/TranslationUtil'
import ImpressumComponent from './ImpressumComponent';
import DonateButton from './DonateButton';
import ManualComponent from './ManualComponent';

const FooterComponent = () => {



      return (
          <div className='footer-container'>
            <div className='content-container'>
                <div className='impressum-item'><ImpressumComponent/></div>
                <div className='impressum-item impressum-item-margin'><DonateButton/></div>
                <div className='impressum-item impressum-item-margin'><ManualComponent/></div>
            </div>
          </div>
      );
      
    };



export default FooterComponent;
