// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".timeline-class {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  min-width: 47vw;\n  max-width: 47vw;\n  min-height: 40vh;\n  max-height: 85vh;\n}\n\n.info-text {\n  font-size: 26px;\n  color: white;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}", "",{"version":3,"sources":["webpack://./src/TimelineComponent.scss","webpack://./src/FontSizes.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;AAOJ;;AAJE;EACE,eCXE;EDYF,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAOJ","sourcesContent":["@import './FontSizes.scss';\n\n.timeline-class {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    min-width: 47vw;\n    max-width: 47vw;\n    min-height: 40vh;\n    max-height: 85vh;\n  }\n\n  .info-text {\n    font-size: $LXL;\n    color: white;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}","$S: 12px;\n$M: 14px;\n$L: 16px;\n$LXL: 26px;\n$XL: 30px;\n$XXL: 40px;\n\n:export {\n    s: $S;\n    m: $M;\n    l: $L;\n    xl: $XL;\n    xxl: $XXL;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"s": "12px",
	"m": "14px",
	"l": "16px",
	"xl": "30px",
	"xxl": "40px"
};
export default ___CSS_LOADER_EXPORT___;
