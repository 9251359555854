import './App.scss';
import './Colors.scss';
import React, { useState } from "react";
import NavigationBar from './NavigationBar';
import MainComponent from './MainComponent';
import TimelineComponent from './TimelineComponent';
import ToneTimelineComponent from './ToneTimelineComponent';
import SourceCountryPieComponent from './SourceCountryPieComponent';
import FooterComponent from './FooterComponent';
import MobileNavigationBar from './MobileNavigationBar';
import useIsMobile from './hooks/isMobile';

const App = () => {
  const [selectedTopic, setSelectedTopic] = useState("Bitcoin");
  const [selectedTopicAlt, setSelectedTopicAlt] = useState("BTC");
  const [selectedMentioningThreshold, setSelectedMentioningThreshold] = useState(1);
  const [selectedLocation, setSelectedLocation] = useState('global');
  const [selectedTimespanNumber, setSelectedTimespanNumber] = useState('1');
  const [selectedTimespanUnit, setSelectedTimespanUnit] = useState('d');
  const [todaysAvgTone, setTodaysAvgTone] = useState(0);
  const [yesterdaysAvgTone, setYesterdaysAvgTone] = useState(0);
  const [isGodmode, setIsGodeMode] = useState(false);
  const isMobile = useIsMobile();


  const handleAvgToneUpdate = (todaysAvgTone, yesterdaysAvgTone) => {
    setTodaysAvgTone(todaysAvgTone);
    setYesterdaysAvgTone(yesterdaysAvgTone);
  };

  const handleSelectedLocationUpdate = (selectedLocation) => {
    setSelectedLocation(selectedLocation);
  }


  const handleSelectChangeTopic = (selectedTopic, selectedTopicAlt) => {
    // Handle the selected value here
    setSelectedTopic(selectedTopic);
    setSelectedTopicAlt(selectedTopicAlt);
  };

  const handleSelectChangeMentioningThreshold = (selectedMentioningThreshold) => {
    // Handle the selected value here
    setSelectedMentioningThreshold(selectedMentioningThreshold);
  };

  const handleSelectChangeTimespanNumber = (selectedTimespanNumber) => {
    // Handle the selected value here
    setSelectedTimespanNumber(selectedTimespanNumber);
  };

  const handleSelectChangeTimespanUnit = (selectedTimespanUnit) => {
    // Handle the selected value here
    setSelectedTimespanUnit(selectedTimespanUnit);
  };

  const handleChangeIsGodmode = (godMode) => {
    setIsGodeMode(godMode);
  }

  return (
    <div className="App">
      {isMobile ? 
      <MobileNavigationBar onSelectChangeTopic={handleSelectChangeTopic} onSelectChangeMentioningThreshold={handleSelectChangeMentioningThreshold} onSelectChangeTimespanNumber={handleSelectChangeTimespanNumber} onSelectChangeTimespanUnit={handleSelectChangeTimespanUnit} title={"Cointernational"} onChangeIsGodmode={handleChangeIsGodmode} /> 
      : 
      <NavigationBar onSelectChangeTopic={handleSelectChangeTopic} onSelectChangeMentioningThreshold={handleSelectChangeMentioningThreshold} onSelectChangeTimespanNumber={handleSelectChangeTimespanNumber} onSelectChangeTimespanUnit={handleSelectChangeTimespanUnit} title={"Cointernational"} onChangeIsGodmode={handleChangeIsGodmode} />
    }
      <MainComponent selectedTopic={selectedTopic} selectedTopicAlt={selectedTopicAlt} mentioningThreshold={selectedMentioningThreshold} todaysAvgTone={todaysAvgTone} yesterdaysAvgTone={yesterdaysAvgTone} timespanNumber={selectedTimespanNumber} timespanUnit={selectedTimespanUnit} onUpdateSelectedLocationCallback={handleSelectedLocationUpdate} isGodmode={isGodmode}></MainComponent>
      <div className='ChartContainer'>
        <div className='Chart'>
          <div className='Section-title'>VOLUME TIMELINE</div>
          <TimelineComponent selectedTopic={selectedTopic} selectedTopicAlt={selectedTopicAlt} mentioningThreshold={selectedMentioningThreshold} selectedLocation={selectedLocation} timespanNumber={selectedTimespanNumber} timespanUnit={selectedTimespanUnit}></TimelineComponent>
        </div>
        <div className='Chart'>
          <div className='Section-title'>TONE TIMELINE</div>
          <ToneTimelineComponent selectedTopic={selectedTopic} selectedTopicAlt={selectedTopicAlt} mentioningThreshold={selectedMentioningThreshold} onAvgToneUpdateCallback={handleAvgToneUpdate} selectedLocation={selectedLocation}></ToneTimelineComponent>
        </div>
      </div>
      <div className='Chart'>
        <div className='Section-title-pie'>NEWS BY REGION</div>
        <div className='Pie-chart'>
          <SourceCountryPieComponent selectedTopic={selectedTopic} selectedTopicAlt={selectedTopicAlt} mentioningThreshold={selectedMentioningThreshold} timespanNumber={selectedTimespanNumber} timespanUnit={selectedTimespanUnit}></SourceCountryPieComponent>
        </div>
      </div>
      <FooterComponent></FooterComponent>
    </div>
  );

}

export default App;
