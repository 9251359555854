import React, { useState, useEffect, useRef } from 'react';
import { Line } from 'react-chartjs-2';
import Chart from 'chart.js/auto'; // NEEDED, DON'T DELETE!!!
import './TimelineComponent.scss';
import DateConverter from './DateConverter';
import { fetchTimeLineDataFromGdelt } from './utils/GdeltUtil';
import colors from './Colors.scss';

const TimelineComponent = ({selectedTopic, selectedTopicAlt, mentioningThreshold, selectedLocation}) => {

    const [currentTimeline, setCurrentTimeline] = useState([]);
    const [currentDates, setCurrentDates] = useState([]);
    const [currentArticleCounts, setCurrentArticleCounts] = useState([]);
    const dateToArticleCountMap = useRef(new Map());

    useEffect(() => {
        fetchTimeline();
        removeOldValues();
    }, [selectedTopic, mentioningThreshold, selectedLocation]);

    const fetchTimeline = async () => {
        if(selectedTopic != null) {
            const timeline = await fetchTimeLineDataFromGdelt(selectedLocation, selectedTopic, selectedTopicAlt, mentioningThreshold);
            setCurrentTimeline(timeline);
            if(timeline === null) {
                return;
            }
            const dateDays = timeline.data.timeline[0].data;
            for(let i = dateDays.length / 2; i < dateDays.length; i++) {
                setCurrentDates((currentDates) => {
                    const updatedMeshes = [...currentDates, DateConverter.getFormattedDate(dateDays[i].date)];
                    return updatedMeshes;
                });
                setCurrentArticleCounts((currentDates) => {
                    const updatedMeshes = [...currentDates, dateDays[i].value];
                    return updatedMeshes;   
                });
                dateToArticleCountMap.current.set(DateConverter.getFormattedDate(dateDays[i].date), dateDays[i].value)
            }
        }
    }

    const dateLabels = Array.from(dateToArticleCountMap.current.keys());
    const articleCounts = Array.from(dateToArticleCountMap.current.values());
    
    const data = {
      labels: dateLabels,
      datasets: [
        {
        label: 'Article Count', 
        data: articleCounts,
        // Linecolor
        borderColor: 'rgba(75, 192, 192, 0.3)',
        backgroundColor: 'rgba(75, 192, 192, 0.03)', 
        fill: true,
        pointRadius: 7,
        pointHoverRadius: 10,
        // PointColor
        pointBackgroundColor: colors.chartContentColor,
        cubicInterpolationMode: 'monotone',
        },
      ],
    };
        
    const options = {
        scales: {
          y: {
            type: 'linear',
            beginAtZero: false,
            ticks: {
              color: colors.white,
              callback: (value, index, values) => {
                // Display every second y-axis label
                if (index % 2 === 0) {
                    return value;
                }
                return ''; // Hide labels for other indices
            },
            },
            grid: {
              display: true,
              color: 'rgba(255, 255, 255, 0.01)', // Set the grid color for the y-axis
            },
          },
          x: {
            ticks: {
              color: colors.white,
              callback: (value, index, values) => {
                // Display every second date label
                if (index % 2 === 0) {
                    // Access the date property from the dateLabels array
                    return dateLabels[index];
                }
            },
            },
            grid: {
                display: true, // Display grid lines
                color: 'rgba(255, 255, 255, 0.05)', // Set the grid color for the y-axis
              },
          },
        },
    plugins: {
        title: {
        display: true,
        text: 'Article Count Per Day',
        color: colors.white,
        padding: {
            bottom: 20,
          },
        },
        legend: {
            display: false,
        },
    },
    maintainAspectRatio: false,
    responsive: true,
    };

    const removeOldValues = () => {
        setCurrentDates([]);
        setCurrentArticleCounts([]);
    }

    return (
        <div className='timeline-class'>
            {selectedLocation === 'nocountry' ? (
                <p className='info-text'>This chart is not available for "no country".</p>
            ) : (
                <Line data={data} options={options} />
            )}
        </div>
    );
};

export default TimelineComponent;
