import axios from 'axios';

export async function fetchArticlesFromGdelt(mentioningThreshold, selectedTopic, selectedTopicAlt, timespanNumber, timespanUnit) {
    try {
        var requestString = "";
        if (mentioningThreshold !== 1) {
            if (selectedTopic.toLowerCase().includes(selectedTopicAlt.toLowerCase())) {
                requestString = `https://api.gdeltproject.org/api/v2/doc/doc?&query=repeat${mentioningThreshold}:"${selectedTopic}"&mode=artlist&maxrecords=250&format=json&timespan=${timespanNumber + timespanUnit}`;
            } else {
                requestString = `https://api.gdeltproject.org/api/v2/doc/doc?&query=(repeat${mentioningThreshold}:"${selectedTopic}" OR repeat${mentioningThreshold}:"${selectedTopicAlt}")&mode=artlist&maxrecords=250&format=json&timespan=${timespanNumber + timespanUnit}`;
            }
        } else {
            if (selectedTopic.toLowerCase().includes(selectedTopicAlt.toLowerCase())) {
                requestString = `https://api.gdeltproject.org/api/v2/doc/doc?&query=${selectedTopic}&mode=artlist&maxrecords=250&format=json&timespan=${timespanNumber + timespanUnit}`
            } else {
                requestString = `https://api.gdeltproject.org/api/v2/doc/doc?&query=(${selectedTopic} OR ${selectedTopicAlt})&mode=artlist&maxrecords=250&format=json&timespan=${timespanNumber + timespanUnit}`
            }
        }
        const response = await axios.get(requestString);
        return response.data.articles;
    } catch(error) {
        console.error("error during fetchArticlesFromGdelt call", error);
        return null;
    }
};

export async function fetchArticleCountsFromGdelt(mentioningThreshold, selectedTopic, selectedTopicAlt, selectedLocation) {
    try {
        var requestString = "";
        if(selectedLocation === 'nocountry') {
            return null;
        }
        if(selectedLocation !== 'global') {
            if (mentioningThreshold !== 1) {
                if (selectedTopic.toLowerCase().includes(selectedTopicAlt.toLowerCase())) {
                    requestString = `https://api.gdeltproject.org/api/v2/doc/doc?&query=repeat${mentioningThreshold}:"${selectedTopic}" sourcecountry:${selectedLocation}&mode=timelinevolraw&timespan=1m&format=JSON`;
                } else {
                    requestString = `https://api.gdeltproject.org/api/v2/doc/doc?&query=(repeat${mentioningThreshold}:"${selectedTopic}" OR repeat${mentioningThreshold}:"${selectedTopicAlt}") sourcecountry:${selectedLocation}&mode=timelinevolraw&timespan=1m&format=JSON`;
                }
            } else {
                if (selectedTopic.toLowerCase().includes(selectedTopicAlt.toLowerCase())) {
                    requestString = `https://api.gdeltproject.org/api/v2/doc/doc?&query=${selectedTopic} sourcecountry:${selectedLocation}&mode=timelinevolraw&timespan=1m&format=JSON`
                } else {
                    requestString = `https://api.gdeltproject.org/api/v2/doc/doc?&query=(${selectedTopic} OR ${selectedTopicAlt}) sourcecountry:${selectedLocation}&mode=timelinevolraw&timespan=1m&format=JSON`
                }
            }
        } else {
            if (mentioningThreshold !== 1) {
                if (selectedTopic.toLowerCase().includes(selectedTopicAlt.toLowerCase())) {
                    requestString = `https://api.gdeltproject.org/api/v2/doc/doc?&query=repeat${mentioningThreshold}:"${selectedTopic}"&mode=timelinevolraw&timespan=1m&format=JSON`;
                } else {
                    requestString = `https://api.gdeltproject.org/api/v2/doc/doc?&query=(repeat${mentioningThreshold}:"${selectedTopic}" OR repeat${mentioningThreshold}:"${selectedTopicAlt}")&mode=timelinevolraw&timespan=1m&format=JSON`
                }
            } else {
                if (selectedTopic.toLowerCase().includes(selectedTopicAlt.toLowerCase())) {
                    requestString = `https://api.gdeltproject.org/api/v2/doc/doc?&query=${selectedTopic}&mode=timelinevolraw&timespan=1m&format=JSON`;
                } else {
                    requestString = `https://api.gdeltproject.org/api/v2/doc/doc?&query=(${selectedTopic} OR ${selectedTopicAlt})&mode=timelinevolraw&timespan=1m&format=JSON`;
                }
            }
        }
        
        const timeline = await axios.get(requestString);
        return timeline.data.timeline[0].data;
    } catch (error) {
        console.error("error during fetchArticleCounts call" , error);
    }
        
}

export async function fetchSelectedArticlesFromGdelt(selectedLocation, mentioningThreshold, selectedTopic, selectedTopicAlt, timespanNumber, timespanUnit) {
    try {
        var requestString = "";
        if(selectedLocation !== 'global') {
          if (mentioningThreshold !== 1) {
            if (selectedTopic.toLowerCase().includes(selectedTopicAlt.toLowerCase())) {
                requestString = `https://api.gdeltproject.org/api/v2/doc/doc?&query=repeat${mentioningThreshold}:"${selectedTopic}" sourcecountry:${selectedLocation}&mode=artlist&maxrecords=100&format=json&timespan=${timespanNumber + timespanUnit}`
            } else {
                requestString = `https://api.gdeltproject.org/api/v2/doc/doc?&query=(repeat${mentioningThreshold}:"${selectedTopic}" OR repeat${mentioningThreshold}:"${selectedTopicAlt}") sourcecountry:${selectedLocation}&mode=artlist&maxrecords=100&format=json&timespan=${timespanNumber + timespanUnit}`
            }
          } else {
            if (selectedTopic.toLowerCase().includes(selectedTopicAlt.toLowerCase())) {
                requestString = `https://api.gdeltproject.org/api/v2/doc/doc?&query=${selectedTopic} sourcecountry:${selectedLocation}&mode=artlist&maxrecords=100&format=json&timespan=${timespanNumber + timespanUnit}`
            } else {
                requestString = `https://api.gdeltproject.org/api/v2/doc/doc?&query=(${selectedTopic} OR ${selectedTopicAlt}) sourcecountry:${selectedLocation}&mode=artlist&maxrecords=100&format=json&timespan=${timespanNumber + timespanUnit}`
            }
          }
        } else {
          if (mentioningThreshold !== 1) {
            if (selectedTopic.toLowerCase().includes(selectedTopicAlt.toLowerCase())) {
                requestString = `https://api.gdeltproject.org/api/v2/doc/doc?&query=repeat${mentioningThreshold}:"${selectedTopic}"&mode=artlist&maxrecords=100&format=json&timespan=${timespanNumber + timespanUnit}`
            } else {
                requestString = `https://api.gdeltproject.org/api/v2/doc/doc?&query=(repeat${mentioningThreshold}:"${selectedTopic}" OR repeat${mentioningThreshold}:"${selectedTopicAlt}")&mode=artlist&maxrecords=100&format=json&timespan=${timespanNumber + timespanUnit}`
            }
          } else {
            if (selectedTopic.toLowerCase().includes(selectedTopicAlt.toLowerCase())) {
                requestString = `https://api.gdeltproject.org/api/v2/doc/doc?&query=${selectedTopic}&mode=artlist&maxrecords=100&format=json&timespan=${timespanNumber + timespanUnit}`
            } else {
                requestString = `https://api.gdeltproject.org/api/v2/doc/doc?&query=(${selectedTopic} OR ${selectedTopicAlt})&mode=artlist&maxrecords=100&format=json&timespan=${timespanNumber + timespanUnit}`
            }
          }
        }
        const response = await axios.get(requestString);
        return response.data.articles;

    } catch (error) {
        console.error("error during fetchArtilesForSelectedLocationArticlesComponent call", error);
    }


}

export async function fetchPieChartDataFromGdelt(selectedTopic, selectedTopicAlt,  mentioningThreshold, timespanNumber, timespanUnit) {
    try {
        var requestString = "";
        if (mentioningThreshold !== 1) {
            requestString = `https://api.gdeltproject.org/api/v2/doc/doc?&query=(repeat${mentioningThreshold}:"${selectedTopic}" OR repeat${mentioningThreshold}:"${selectedTopicAlt}")&mode=artlist&maxrecords=250&format=json&timespan=${timespanNumber + timespanUnit}&sort=relevancy`
        } else {
            requestString = `https://api.gdeltproject.org/api/v2/doc/doc?&query=(${selectedTopic} OR ${selectedTopicAlt})&mode=artlist&maxrecords=250&format=json&timespan=${timespanNumber + timespanUnit}&sort=relevancy`
        }
        
        const response = await axios.get(requestString);
        return response.data.articles;

    } catch (error) {
        console.error("error during fetchPieChartDataFromGdelt call", error);
    }
}

export async function fetchTimeLineDataFromGdelt(selectedLocation, selectedTopic, selectedTopicAlt, mentioningThreshold) {
    try {
        var requestString = "";
        if(selectedLocation === 'nocountry') {
            return null;
        }
        if(selectedLocation !== 'global') {
            if (mentioningThreshold !== 1) {
                requestString = `https://api.gdeltproject.org/api/v2/doc/doc?&query=(repeat${mentioningThreshold}:"${selectedTopic}" OR repeat${mentioningThreshold}:"${selectedTopicAlt}") sourcecountry:${selectedLocation}&&mode=timelinevolraw&timespan=1m&format=JSON`
            } else {
                requestString = `https://api.gdeltproject.org/api/v2/doc/doc?&query=(${selectedTopic} OR ${selectedTopicAlt}) sourcecountry:${selectedLocation}&&mode=timelinevolraw&timespan=1m&format=JSON`
            }
        } else {
            if (mentioningThreshold !== 1) {
                requestString = `https://api.gdeltproject.org/api/v2/doc/doc?&query=(repeat${mentioningThreshold}:"${selectedTopic}" OR repeat${mentioningThreshold}:"${selectedTopicAlt}")&mode=timelinevolraw&timespan=1m&format=JSON`
            } else {
                requestString = `https://api.gdeltproject.org/api/v2/doc/doc?&query=(${selectedTopic} OR ${selectedTopicAlt})&mode=timelinevolraw&timespan=1m&format=JSON`
            }
        }
        
        return axios.get(requestString);
    } catch (error) {
        console.error("error during fetchTimeLineDataFromGdelt call", error);
    }
}

export async function fetchToneTimeLineDataFromGdelt(selectedTopic, selectedTopicAlt, selectedLocation, mentioningThreshold) {
    try {
        var requestString = "";
        if(selectedLocation === 'nocountry') {
            return null;
        }
        if(selectedLocation !== 'global') {
            if (mentioningThreshold !== 1) {
                requestString = `https://api.gdeltproject.org/api/v2/doc/doc?&query=(repeat${mentioningThreshold}:"${selectedTopic}" OR repeat${mentioningThreshold}:"${selectedTopicAlt}") sourcecountry:${selectedLocation}&&mode=timelinetone&timespan=1m&format=JSON`
            } else {
                requestString = `https://api.gdeltproject.org/api/v2/doc/doc?&query=(${selectedTopic} OR ${selectedTopicAlt}) sourcecountry:${selectedLocation}&&mode=timelinetone&timespan=1m&format=JSON`
            }
        } else {
            if (mentioningThreshold !== 1) {
                requestString = `https://api.gdeltproject.org/api/v2/doc/doc?&query=(repeat${mentioningThreshold}:"${selectedTopic}" OR repeat${mentioningThreshold}:"${selectedTopicAlt}")&mode=timelinetone&timespan=1m&format=JSON`
            } else {
                requestString = `https://api.gdeltproject.org/api/v2/doc/doc?&query=(${selectedTopic} OR ${selectedTopicAlt})&mode=timelinetone&timespan=1m&format=JSON`
            }
        }

        return await axios.get(requestString);
    } catch (error) {
        console.error("error during fetchToneTimeLineDataFromGdelt call", error);
    }

}
    