import React, { useState, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import Chart from 'chart.js/auto'; // NÖTIG, NICHT LÖSCHEN!!!
import './SourceCountryPieComponent.css';
import { fetchPieChartDataFromGdelt } from './utils/GdeltUtil';

const SourceCountryPieComponent = ({ selectedTopic, selectedTopicAlt, mentioningThreshold, timespanNumber, timespanUnit }) => {
  const [sourceCountryToMentioningsMap, setSourceCountryToMentioningsMap] = useState(new Map());

  useEffect(() => {
    removeOldValues();
    fetchArticles();
  }, [selectedTopic, mentioningThreshold, timespanNumber, timespanUnit]);

  const fetchArticles = async () => {
    if (selectedTopic != null) {
      const newMap = new Map(sourceCountryToMentioningsMap);
      for (let key of newMap.keys()) {
        newMap.set(key, 0);
      }
      
      const articles = await fetchPieChartDataFromGdelt(selectedTopic, selectedTopicAlt,  mentioningThreshold, timespanNumber, timespanUnit);
      
      for (let i = 0; i < articles.length; i++) {
        if (articles[i].sourcecountry === "") {
          articles[i].sourcecountry = 'No country';
        }
        newMap.set(
          articles[i].sourcecountry,
          newMap.get(articles[i].sourcecountry) == null
            ? 1
            : newMap.get(articles[i].sourcecountry) + 1
        );
      }
      
      modifyMap(newMap);
      setSourceCountryToMentioningsMap(newMap);
    }
  };
  
  const modifyMap = (map) => {
    let sortedEntries = Array.from(map.entries()).sort((a, b) => b[1] - a[1]);
    
    let totalArticlesCount = 0;
    for (let i = 0; i < sortedEntries.length; i++) {
      totalArticlesCount += sortedEntries[i][1];
    }

    let topValuesMap = new Map();
    let sumOfOthers = 0;
  
    for (let i = 0; i < sortedEntries.length; i++) {
      if (i < 5) {
        topValuesMap.set(sortedEntries[i][0], calculatePercentage(sortedEntries[i][1], totalArticlesCount));
      } else {
        sumOfOthers += calculatePercentage(sortedEntries[i][1], totalArticlesCount);
      }
    }

    function calculatePercentage(amount, totalAmount) {
      return (amount / totalAmount) * 100;
    }
  
    // Lösche den gesamten Inhalt der ursprünglichen Map
    map.clear();
  
    // Kopiere die Werte aus der topValuesMap in die ursprüngliche Map
    for (let [key, value] of topValuesMap.entries()) {
      map.set(key, value);
    }
  
    // Füge "others" als letzten Schlüssel in die Map ein
    map.set("others", sumOfOthers);
  };

  const removeOldValues = () => {
    setSourceCountryToMentioningsMap(new Map());
  };

  const countryLabels = Array.from(sourceCountryToMentioningsMap.keys());
  const percentages = Array.from(sourceCountryToMentioningsMap.values());

  const data = {
    labels: countryLabels,
    datasets: [
      {
        data: percentages,
        backgroundColor: ['#4bc0c0',
            '#ff8080',
            '#a8c34b',
            '#c080ff',
            '#ffcc66',
            '#55a8a8',
            '#e67f5b',
            '#6e97ff',
            '#b3b300',
            '#ff99cc'],
        hoverOffset: 4,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: true,
        labels: {
          usePointStyle: true,
        },
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            let label = context.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed) {
              const roundedValue = context.parsed.toFixed(1);
              label += roundedValue % 1 === 0 ? roundedValue.split('.')[0] : roundedValue;
              label += '%';
            }
            return label;
          },
        },
      },
    },
  };

  return (
    <div className='Doughnut-chart'>
      <Doughnut data={data} options={options} />
    </div>
  );
};

export default SourceCountryPieComponent;