class CoordinateStorage {
  countryCoordinates = new Map();

  constructor() {
    this.countryCoordinates.set('Afghanistan', { lon: 69.1723, lat: 34.5277 });
    this.countryCoordinates.set('Äland Islands', { lon: 19.94498, lat: 60.09891 });
    this.countryCoordinates.set('Albania', { lon: 19.8172, lat: 41.3317 });
    this.countryCoordinates.set('Algeria', { lon: 3.0548, lat: 36.7538 });
    this.countryCoordinates.set('American Samoa', { lon: -170.1322, lat: -14.2740 });
    this.countryCoordinates.set('Andorra', { lon: 1.5218, lat: 42.5063 });
    this.countryCoordinates.set('Angola', { lon: 17.8739, lat: -11.2027 });
    this.countryCoordinates.set('Anguilla', { lon: -63.0686, lat: 18.2206 });
    this.countryCoordinates.set('Antarctica', { lon: 0, lat: -90 });
    this.countryCoordinates.set('Antigua and Barbuda', { lon: -61.8456, lat: 17.1175 });
    this.countryCoordinates.set('Argentina', { lon: -58.4173, lat: -34.6118 });
    this.countryCoordinates.set('Armenia', { lon: 44.4991, lat: 40.1792 });
    this.countryCoordinates.set('Aruba', { lon: -70.0345, lat: 12.5186 });
    this.countryCoordinates.set('Australia', { lon: 149.1287, lat: -35.282 });
    this.countryCoordinates.set('Austria', { lon: 14.5501, lat: 47.5162 });
    this.countryCoordinates.set('Azerbaijan', { lon: 47.5769, lat: 40.1431 });
    this.countryCoordinates.set('Bahamas', { lon: -77.3505, lat: 25.0343 });
    this.countryCoordinates.set('Bahrain', { lon: 50.5860, lat: 26.2285 });
    this.countryCoordinates.set('Bangladesh', { lon: 90.4125, lat: 23.8103 });
    this.countryCoordinates.set('Barbados', { lon: -59.6167, lat: 13.1000 });
    this.countryCoordinates.set('Belarus', { lon: 27.9534, lat: 53.7098 });
    this.countryCoordinates.set('Belgium', { lon: 4.4699, lat: 50.8503 });
    this.countryCoordinates.set('Belize', { lon: -88.1862, lat: 17.4984 });
    this.countryCoordinates.set('Benin', { lon: 2.6323, lat: 6.4779 });
    this.countryCoordinates.set('Bermuda', { lon: -64.7815, lat: 32.2930 });
    this.countryCoordinates.set('Bhutan', { lon: 89.6360, lat: 27.4661 });
    this.countryCoordinates.set('Bolivia', { lon: -65.2627, lat: -19.0421 });
    this.countryCoordinates.set('Bosnia and Herzegovina', { lon: 18.4131, lat: 43.8563 });
    this.countryCoordinates.set('Botswana', { lon: 25.9231, lat: -24.6282 });
    this.countryCoordinates.set('Brazil', { lon: -47.9292, lat: -15.7801 });
    this.countryCoordinates.set('Brunei', { lon: 114.9481, lat: 4.8903 });
    this.countryCoordinates.set('Bulgaria', { lon: 23.3219, lat: 42.6977 });
    this.countryCoordinates.set('Burkina Faso', { lon: -1.5257, lat: 12.3714 });
    this.countryCoordinates.set('Burundi', { lon: 29.3639, lat: -3.3818 });
    this.countryCoordinates.set('Cambodia', { lon: 104.9160, lat: 11.5494 });
    this.countryCoordinates.set('Cameroon', { lon: 11.5021, lat: 3.8480 });
    this.countryCoordinates.set('Canada', { lon: -75.6919, lat: 45.4215 });
    this.countryCoordinates.set('Cape Verde', { lon: -23.5083, lat: 14.9330 });
    this.countryCoordinates.set('Cayman Islands', { lon: -81.2546, lat: 19.3133 });
    this.countryCoordinates.set('Central African Republic', { lon: 18.5701, lat: 4.3947 });
    this.countryCoordinates.set('Chad', { lon: 15.0445, lat: 12.1348 });
    this.countryCoordinates.set('Chile', { lon: -70.6483, lat: -33.4569 });
    this.countryCoordinates.set('China', { lon: 116.4074, lat: 39.9042 });
    this.countryCoordinates.set('Colombia', { lon: -74.0721, lat: 4.7109 });
    this.countryCoordinates.set('Republic of the Congo', { lon: 15.2663, lat: -4.2767 });
    this.countryCoordinates.set('Democratic Republic of the Congo', { lon: 21.7554, lat: -4.0383 });
    this.countryCoordinates.set('Costa Rica', { lon: -84.0912, lat: 9.9281 });
    this.countryCoordinates.set("Côte d'Ivoire", { lon: -4.0305, lat: 5.5675 });
    this.countryCoordinates.set('Croatia', { lon: 15.9819, lat: 45.8150 });
    this.countryCoordinates.set('Cuba', { lon: -82.3666, lat: 23.1333 });
    this.countryCoordinates.set('Curaçao', { lon: -68.9933, lat: 12.1696 });
    this.countryCoordinates.set('Cyprus', { lon: 33.4299, lat: 35.1264 });
    this.countryCoordinates.set('Czech Republic', { lon: 15.4720, lat: 49.8175 });
    this.countryCoordinates.set('Denmark', { lon: 9.5018, lat: 56.2639 });
    this.countryCoordinates.set('Dominican Republic', { lon: -69.9018, lat: 18.7357 });
    this.countryCoordinates.set('Ecuador', { lon: -78.4678, lat: -0.1807 });
    this.countryCoordinates.set('Egypt', { lon: 31.2357, lat: 30.0444 });
    this.countryCoordinates.set('El Salvador', { lon: -89.2073, lat: 13.6929 });
    this.countryCoordinates.set('Equatorial Guinea', { lon: 8.7806, lat: 3.7527 });
    this.countryCoordinates.set('Eritrea', { lon: 38.9285, lat: 15.3229 });
    this.countryCoordinates.set('Estonia', { lon: 24.7536, lat: 59.4369 });
    this.countryCoordinates.set('Eswatini', { lon: 31.4659, lat: -26.5225 });
    this.countryCoordinates.set('Ethiopia', { lon: 39.7272, lat: 9.0024 });
    this.countryCoordinates.set('Falkland Islands', { lon: -58.4438, lat: -51.7963 });
    this.countryCoordinates.set('Fiji', { lon: 178.0650, lat: -17.7134 });
    this.countryCoordinates.set('Finland', { lon: 25.7482, lat: 61.9241 });
    this.countryCoordinates.set('France', { lon: 2.3522, lat: 48.8566 });
    this.countryCoordinates.set('Gabon', { lon: 9.4500, lat: 0.3858 });
    this.countryCoordinates.set('Gambia', { lon: -16.5780, lat: 13.4527 });
    this.countryCoordinates.set('Georgia', { lon: 44.7930, lat: 41.7099 });
    this.countryCoordinates.set('Germany', { lon: 13.4105, lat: 52.5244 });
    this.countryCoordinates.set('Ghana', { lon: -0.2074, lat: 5.5600 });
    this.countryCoordinates.set('Gibraltar', { lon: -5.3536, lat: 36.1408 });
    this.countryCoordinates.set('Greece', { lon: 23.7275, lat: 37.9838 });
    this.countryCoordinates.set('Greenland', { lon: -42.6043, lat: 71.7069 });
    this.countryCoordinates.set('Grenada', { lon: -61.6822, lat: 12.1173 });
    this.countryCoordinates.set('Guadeloupe', { lon: -61.5310, lat: 16.2650 });
    this.countryCoordinates.set('Guam', { lon: 144.7937, lat: 13.4443 });
    this.countryCoordinates.set('Guatemala', { lon: -90.2295, lat: 14.6349 });
    this.countryCoordinates.set('Guernsey', { lon: -2.5724, lat: 49.4482 });
    this.countryCoordinates.set('Guinea', { lon: -13.6773, lat: 9.9456 });    
    this.countryCoordinates.set('Guinea-Bissau', { lon: -15.1804, lat: 11.8037 });
    this.countryCoordinates.set('Guyana', { lon: -58.1551, lat: 6.8013 });
    this.countryCoordinates.set('Haiti', { lon: -72.2942, lat: 18.5944 });
    this.countryCoordinates.set('Honduras', { lon: -87.2248, lat: 14.0818 });
    this.countryCoordinates.set('Hong Kong', { lon: 114.1772, lat: 22.3193 });
    this.countryCoordinates.set('Hungary', { lon: 19.0402, lat: 47.4979 });
    this.countryCoordinates.set('Iceland', { lon: -21.9426, lat: 64.1353 });
    this.countryCoordinates.set('India', { lon: 77.1025, lat: 28.7041 });
    this.countryCoordinates.set('Indonesia', { lon: 106.8283, lat: -6.2088 });
    this.countryCoordinates.set('Iran', { lon: 53.6880, lat: 32.4279 });
    this.countryCoordinates.set('Iraq', { lon: 44.3615, lat: 33.3152 });
    this.countryCoordinates.set('Ireland', { lon: -6.2675, lat: 53.3441 });
    this.countryCoordinates.set('Isle of Man', { lon: -4.5481, lat: 54.2361 });
    this.countryCoordinates.set('Israel', { lon: 35.2137, lat: 31.7683 });
    this.countryCoordinates.set('Italy', { lon: 12.5674, lat: 41.8719 });
    this.countryCoordinates.set('Jamaica', { lon: -76.7920, lat: 18.1096 });
    this.countryCoordinates.set('Japan', { lon: 139.6917, lat: 35.6895 });
    this.countryCoordinates.set('Jersey', { lon: -2.1266, lat: 49.2144 });
    this.countryCoordinates.set('Jordan', { lon: 35.9453, lat: 31.7683 });
    this.countryCoordinates.set('Kazakhstan', { lon: 66.9237, lat: 48.0196 });
    this.countryCoordinates.set('Kenya', { lon: 36.8219, lat: -1.2921 });
    this.countryCoordinates.set('Kiribati', { lon: -157.3631, lat: 1.8709 });
    this.countryCoordinates.set('North Korea', { lon: 127.5100, lat: 40.3399 });
    this.countryCoordinates.set('South Korea', { lon: 127.7669, lat: 37.5665 });
    this.countryCoordinates.set('Kosovo', { lon: 20.9025, lat: 42.6026 });
    this.countryCoordinates.set('Kuwait', { lon: 47.4818, lat: 29.3759 });
    this.countryCoordinates.set('Kyrgyzstan', { lon: 74.7661, lat: 41.2044 });
    this.countryCoordinates.set('Laos', { lon: 102.4955, lat: 19.8563 });
    this.countryCoordinates.set('Latvia', { lon: 24.6032, lat: 56.8796 });
    this.countryCoordinates.set('Lebanon', { lon: 35.5154, lat: 33.8547 });
    this.countryCoordinates.set('Lesotho', { lon: 27.5120, lat: -29.6099 });
    this.countryCoordinates.set('Liberia', { lon: -9.4295, lat: 6.4281 });
    this.countryCoordinates.set('Libya', { lon: 13.1910, lat: 32.8872 });
    this.countryCoordinates.set('Liechtenstein', { lon: 9.5554, lat: 47.1660 });
    this.countryCoordinates.set('Lithuania', { lon: 25.2797, lat: 54.6896 });
    this.countryCoordinates.set('Luxembourg', { lon: 6.1296, lat: 49.8153 });
    this.countryCoordinates.set('Macau', { lon: 113.5439, lat: 22.1987 });
    this.countryCoordinates.set('Madagascar', { lon: 47.5361, lat: -18.8792 });
    this.countryCoordinates.set('Malawi', { lon: 34.3015, lat: -13.2543 });
    this.countryCoordinates.set('Malaysia', { lon: 101.9758, lat: 3.1390 });
    this.countryCoordinates.set('Maldives', { lon: 73.5093, lat: 4.1755 });
    this.countryCoordinates.set('Mali', { lon: -7.9864, lat: 12.6392 });
    this.countryCoordinates.set('Malta', { lon: 14.3754, lat: 35.9375 });
    this.countryCoordinates.set('Marshall Islands', { lon: 171.1845, lat: 7.1315 });
    this.countryCoordinates.set('Martinique', { lon: -61.0242, lat: 14.6415 });
    this.countryCoordinates.set('Mauritania', { lon: -10.9408, lat: 21.0079 });
    this.countryCoordinates.set('Mauritius', { lon: 57.4984, lat: -20.3484 });
    this.countryCoordinates.set('Mexico', { lon: -99.1332, lat: 19.4326 });
    this.countryCoordinates.set('Moldova', { lon: 28.8600, lat: 47.0105 });
    this.countryCoordinates.set('Monaco', { lon: 7.4197, lat: 43.7325 });
    this.countryCoordinates.set('Mongolia', { lon: 106.9080, lat: 47.9204 });
    this.countryCoordinates.set('Montenegro', { lon: 19.2663, lat: 42.4602 });
    this.countryCoordinates.set('Montserrat', { lon: -62.1939, lat: 16.7425 });
    this.countryCoordinates.set('Morocco', { lon: -6.8691, lat: 33.9905 });
    this.countryCoordinates.set('Mozambique', { lon: 32.5672, lat: -25.9665 });
    this.countryCoordinates.set('Myanmar', { lon: 96.1561, lat: 19.7450 });
    this.countryCoordinates.set('Namibia', { lon: 17.0710, lat: -22.5596 });
    this.countryCoordinates.set('Nauru', { lon: 166.9325, lat: -0.5474 });
    this.countryCoordinates.set('Nepal', { lon: 85.3240, lat: 27.7172 });
    this.countryCoordinates.set('Netherlands', { lon: 4.8952, lat: 52.3702 });
    this.countryCoordinates.set('New Zealand', { lon: 174.7633, lat: -36.8485 });
    this.countryCoordinates.set('Nicaragua', { lon: -86.2362, lat: 12.1475 });
    this.countryCoordinates.set('Niger', { lon: 2.1131, lat: 13.5127 });
    this.countryCoordinates.set('Nigeria', { lon: 3.3792, lat: 6.5244 });
    this.countryCoordinates.set('North Macedonia', { lon: 21.4361, lat: 42.0024 });
    this.countryCoordinates.set('Macedonia', { lon: 21.7170, lat: 41.6086 });
    this.countryCoordinates.set('Norway', { lon: 10.7522, lat: 59.9139 });
    this.countryCoordinates.set('Oman', { lon: 58.5874, lat: 23.6105 });
    this.countryCoordinates.set('Pakistan', { lon: 73.0479, lat: 33.6844 });
    this.countryCoordinates.set('Palau', { lon: 134.5541, lat: 7.5000 });
    this.countryCoordinates.set('Palestine', { lon: 35.2137, lat: 31.9522 });
    this.countryCoordinates.set('Panama', { lon: -79.5241, lat: 8.9783 });
    this.countryCoordinates.set('Papua New Guinea', { lon: 147.1910, lat: -9.4438 });
    this.countryCoordinates.set('Paraguay', { lon: -57.6471, lat: -25.2637 });
    this.countryCoordinates.set('Peru', { lon: -77.0428, lat: -12.0464 });
    this.countryCoordinates.set('Philippines', { lon: 121.7740, lat: 12.8797 });
    this.countryCoordinates.set('Poland', { lon: 19.1451, lat: 51.9194 });
    this.countryCoordinates.set('Portugal', { lon: -9.1393, lat: 38.7223 });
    this.countryCoordinates.set('Puerto Rico', { lon: -66.5901, lat: 18.2208 });
    this.countryCoordinates.set('Qatar', { lon: 51.5324, lat: 25.2768 });
    this.countryCoordinates.set('Romania', { lon: 26.1025, lat: 44.4268 });
    this.countryCoordinates.set('Russia', { lon: 37.6176, lat: 55.7558 });
    this.countryCoordinates.set('Rwanda', { lon: 29.8739, lat: -1.9403 });
    this.countryCoordinates.set('Samoa', { lon: -172.1046, lat: -13.7590 });
    this.countryCoordinates.set('San Marino', { lon: 12.4578, lat: 43.9424 });
    this.countryCoordinates.set('Saudi Arabia', { lon: 46.7222, lat: 24.7136 });
    this.countryCoordinates.set('Scotland', { lon: -3.1883, lat: 55.9533 });
    this.countryCoordinates.set('Senegal', { lon: -17.4467, lat: 14.6928 });
    this.countryCoordinates.set('Serbia', { lon: 20.4651, lat: 44.7866 });
    this.countryCoordinates.set('Seychelles', { lon: 55.4480, lat: -4.6796 });
    this.countryCoordinates.set('Sierra Leone', { lon: -11.7799, lat: 8.4606 });
    this.countryCoordinates.set('Singapore', { lon: 103.8530, lat: 1.2895 });
    this.countryCoordinates.set('Slovakia', { lon: 17.1077, lat: 48.1486 });
    this.countryCoordinates.set('Slovak Republic', { lon: 19.6990, lat: 48.6690 });
    this.countryCoordinates.set('Slovenia', { lon: 14.5058, lat: 46.0569 });
    this.countryCoordinates.set('Somalia', { lon: 45.3433, lat: 2.0469 });
    this.countryCoordinates.set('South Africa', { lon: 28.1871, lat: -25.7461 });
    this.countryCoordinates.set('South Sudan', { lon: 31.5790, lat: 4.8594 });
    this.countryCoordinates.set('Spain', { lon: -3.7038, lat: 40.4168 });
    this.countryCoordinates.set('Sri Lanka', { lon: 79.9607, lat: 6.9271 });
    this.countryCoordinates.set('Sudan', { lon: 32.5599, lat: 15.5007 });
    this.countryCoordinates.set('Suriname', { lon: -55.2038, lat: 5.8520 });
    this.countryCoordinates.set('Sweden', { lon: 18.0645, lat: 59.3293 });
    this.countryCoordinates.set('Switzerland', { lon: 7.4474, lat: 46.9480 });
    this.countryCoordinates.set('Syria', { lon: 36.3201, lat: 33.5159 });
    this.countryCoordinates.set('Taiwan', { lon: 121.5201, lat: 25.0328 });
    this.countryCoordinates.set('Tajikistan', { lon: 68.7735, lat: 38.5739 });
    this.countryCoordinates.set('Tanzania', { lon: 35.7469, lat: -6.3690 });
    this.countryCoordinates.set('Thailand', { lon: 100.5018, lat: 13.7563 });    
    this.countryCoordinates.set('Togo', { lon: 1.2195, lat: 6.1228 });
    this.countryCoordinates.set('Tonga', { lon: -175.1982, lat: -21.1789 });
    this.countryCoordinates.set('Trinidad and Tobago', { lon: -61.2225, lat: 10.6918 });
    this.countryCoordinates.set('Tunisia', { lon: 9.5375, lat: 33.8869 });
    this.countryCoordinates.set('Turkey', { lon: 32.8597, lat: 39.9334 });
    this.countryCoordinates.set('Turkmenistan', { lon: 59.5563, lat: 38.9697 });
    this.countryCoordinates.set('Uganda', { lon: 32.2903, lat: 1.3733 });
    this.countryCoordinates.set('Ukraine', { lon: 30.5234, lat: 50.4501 });
    this.countryCoordinates.set('United Arab Emirates', { lon: 54.3773, lat: 24.4539 });
    this.countryCoordinates.set('United Kingdom', { lon: -0.1276, lat: 51.5074 });
    this.countryCoordinates.set('United States', { lon: -77.0369, lat: 38.8951 });    
    this.countryCoordinates.set('Uruguay', { lon: -56.1645, lat: -34.8941 });
    this.countryCoordinates.set('Uzbekistan', { lon: 69.2401, lat: 41.2995 });
    this.countryCoordinates.set('Vatican City', { lon: 12.4534, lat: 41.9029 });
    this.countryCoordinates.set('Venezuela', { lon: -66.9036, lat: 10.4806 });
    this.countryCoordinates.set('Vietnam', { lon: 105.8544, lat: 21.0285 });
    this.countryCoordinates.set('Wales', { lon: -3.1390, lat: 52.4153 });
    this.countryCoordinates.set('Yemen', { lon: 44.2067, lat: 15.5527 });
    this.countryCoordinates.set('Zambia', { lon: 28.2931, lat: -15.3982 });
    this.countryCoordinates.set('Zimbabwe', { lon: 31.0672, lat: -17.8318 });

  }

  getCoordinatesForCountry = (country) => {
    //console.log(country);
    return country  !== "" && this.countryCoordinates.has(country) ? this.countryCoordinates.get(country) : {lon: 0, lat: 0};
  };
}

export default CoordinateStorage;