import React, { useState, useEffect, useRef } from 'react';
import './SelectedArticles.scss';
import SelectedArticle from './SelectedArticle.js';
import { fetchSelectedArticlesFromGdelt, fetchArticlesFromGdelt } from './utils/GdeltUtil';
import DateConverter from './DateConverter';

const SelectedLocationArticlesComponent = ({selectedTopic, selectedTopicAlt, mentioningThreshold, selectedLocation, timespanNumber, timespanUnit, fullScreen, sortBy, isGodmode}) => {

    const [currentArticles, setCurrentArticles] = useState([]);
    const [articlesSortedByRelevancy, setArticlesSortedByRelevancy] = useState([]);

    useEffect(() => {
        console.log(selectedLocation);
        if (selectedLocation != "") {
            fetchData();
        }
    }, [selectedTopic, mentioningThreshold, selectedLocation, timespanNumber, timespanUnit]);

    useEffect(() => {
        if (sortBy === "latest") {
            const sortedByLatest = sortArticlesByDate(articlesSortedByRelevancy);
            setCurrentArticles(sortedByLatest);
        } else {
            setCurrentArticles(articlesSortedByRelevancy);
        }
    }, [sortBy]);

    const sortArticlesByDate = (articles) => {
        const sortedByDate = articles.slice().sort((a, b) => {
          const dateA = new Date(DateConverter.getFormattedDate(a.seendate));
          const dateB = new Date(DateConverter.getFormattedDate(b.seendate));
          return dateB - dateA;
        });
        return sortedByDate;
      };


    const fetchData = async () => {
        console.log("call " + selectedLocation);
        var articles;
        if(selectedLocation !== 'nocountry') {
          articles = await fetchSelectedArticlesFromGdelt(selectedLocation, mentioningThreshold, selectedTopic, selectedTopicAlt, timespanNumber, timespanUnit);
        } else {
          articles = await fetchArticlesFromGdelt(mentioningThreshold, selectedTopic, selectedTopicAlt, timespanNumber, timespanUnit);
          articles = articles.filter(a => a.sourcecountry === '');
        }
        setArticlesSortedByRelevancy(articles);

        if (sortBy === "latest") {
          const sortedByLatest = sortArticlesByDate(articles);
          setCurrentArticles(sortedByLatest); 
        } else {
          setCurrentArticles(articles);
        }
      };

      return (
        <div>
        {currentArticles && currentArticles.length > 0 && (
            currentArticles.map((article, index) => (
            <SelectedArticle key={index} selectedArticle={article} fullScreen={fullScreen} />
            ))
        )}
        </div>
      );
      
    };



export default SelectedLocationArticlesComponent;
