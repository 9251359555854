import React, { useState, useEffect, useRef } from 'react';
import './Colors.scss';
import './MainComponent.scss';
import * as THREE from 'three';
import colors from './Colors.scss';
import map from './assets/earth_living.jpg';
import bumpMap from './assets/elev_bump_8k.jpg';
import earth_clouds from './assets/fair_clouds_8k.jpg';
import { OrbitControls } from 'three/addons/controls/OrbitControls.js';
import CoordinateStorage from './CoordinateStorage';
import LocationSphereMesh from './LocationSphereMesh.js';
import ArticlesComponent from './ArticlesComponent';
import MobileArticlesComponent from './MobileArticlesComponent';
import CurrencyComponent from './CurrencyComponent';
import MobileCurrencyComponent from './MobileCurrencyComponent';
import { fetchArticlesFromGdelt, fetchArticleCountsFromGdelt } from './utils/GdeltUtil';
import { fetchPriceFromCoinGecko } from './utils/CoinGeckoUtil';


const MainComponent = ({ selectedTopic, selectedTopicAlt, mentioningThreshold, timespanNumber, timespanUnit, yesterdaysAvgTone, todaysAvgTone, onUpdateSelectedLocationCallback, isGodmode }) => {
  const [selectedValue, setSelectedValue] = useState(selectedTopic);
  const [currentArticles, setCurrentArticles] = useState([]);
  const [currentPrice, setCurrentPrice] = useState();
  const [twentyFourHoursChange, setTwentyFourHoursChange] = useState();
  const [todaysArticleCount, setTodaysArticleCount] = useState();
  const [yesterdaysArticleCount, setYesterdaysArticleCount] = useState();
  const [fullScreen, setFullScreen] = useState(false);
  const containerRef = useRef(); //Container für das 3D Model
  const currentLocationMeshesRef = useRef([]);
  const currentLocationRingMeshesRef = useRef([]);
  const modelCreatedRef = useRef(false);
  const earthMeshRef = useRef();
  const animationRef = useRef(null);
  const cloudMeshRef = useRef();
  const cameraRef = useRef();
  const rendererRef = useRef();
  const controlsRef = useRef();
  const canvasRef = useRef();
  const lightRef = useRef();
  const sceneRef = useRef();
  const raycasterRef = useRef();
  const mouseRef = useRef(new THREE.Vector2());
  const [selectedLocation, setSelectedLocation] = useState('global');
  const hoveredMesh = useRef();
  const [isMobile, setIsMobile] = useState(false);
  const [newArticlesCountdown, setNewArticlesCountdown] = useState('0');

  useEffect(() => {
    const checkIsMobile = () => {
      const mediaQuery = window.matchMedia('(max-width: 1400px)');
      setIsMobile(mediaQuery.matches);
    };


    //should not be needed
    checkIsMobile();

    window.addEventListener('resize', checkIsMobile);

    return () => {
      window.removeEventListener('resize', checkIsMobile);
    };
  }, []);

  useEffect(() => {
    setFullScreen(false);
    if(!isMobile && rendererRef.current) {
      setup3DModel();
      create3DModel();
    }
  }, [isMobile]);

  var material, cloudMaterial;
  var cloudGeometry;
  var animationSpeed = 0.0005;
  const coordinateStorage = new CoordinateStorage();

  /**
   * Creates the 3D Model of the earth
   */
  const setup3DModel = () => {
    material = new THREE.MeshStandardMaterial();
    material.map = new THREE.TextureLoader().load(map)
    material.bumpMap = new THREE.TextureLoader().load(bumpMap);
    material.bumpScale = 0.2;

    cloudMaterial = new THREE.MeshPhongMaterial();
    cloudMaterial.map = new THREE.TextureLoader().load(earth_clouds);
    cloudMaterial.transparent = true;
    cloudMaterial.opacity = 0.3;
    cloudGeometry = new THREE.SphereGeometry(1.01, 32, 32);

    lightRef.current = new THREE.PointLight(0xffffff, 1);
    lightRef.current.position.set(5, 0, 0);

    sceneRef.current = new THREE.Scene();
    // left here for testing purposes
    //scene.background = new THREE.Color(0x24356);

    cameraRef.current = new THREE.PerspectiveCamera(50, window.innerWidth / window.innerHeight, 1, 1000);
    cameraRef.current.position.z = 2.8;

    rendererRef.current = new THREE.WebGLRenderer({ alpha: true });
    rendererRef.current.setSize(window.innerWidth / 1.75, window.innerHeight);
    cameraRef.current.aspect = window.innerWidth / 1.75 / window.innerHeight;
    cameraRef.current.updateProjectionMatrix();
    window.addEventListener('resize', function () {
      rendererRef.current.setSize(window.innerWidth / 1.75, window.innerHeight);
      cameraRef.current.aspect = window.innerWidth / 1.75 / window.innerHeight;
      cameraRef.current.updateProjectionMatrix();
    });

    initCanvas();


    document.body.appendChild(rendererRef.current.domElement);
    //controlsRef.current = new TrackballControls(cameraRef.current, rendererRef.current.domElement);
    controlsRef.current = new OrbitControls(cameraRef.current, rendererRef.current.domElement);
    controlsRef.current.minDistance = 2.1;
    controlsRef.current.maxDistance = 8;
    controlsRef.current.noPan = true;

    sceneRef.current.add(lightRef.current);
  }

  const animate = () => {
    animationRef.current = requestAnimationFrame(animate);
    //earthMeshRef.current.rotation.y += animationSpeed;
    cloudMeshRef.current.rotation.y += animationSpeed * 0.1;
    controlsRef.current.autoRotate = true;
    controlsRef.current.autoRotateSpeed = animationSpeed * 400;
    controlsRef.current.enablePan = false;
    controlsRef.current.rotateSpeed = 0.1;
    controlsRef.current.enableDamping = true;
    controlsRef.current.dampingFactor = 0.15;
    controlsRef.current.minPolarAngle = Math.PI * 0.2; // Minimaler Azimutwinkel aufheben
    controlsRef.current.maxPolarAngle = Math.PI * 0.8; // Maximaler Azimutwinkel aufheben    
    lightRef.current.position.copy(cameraRef.current.position);
    controlsRef.current.update();

    rendererRef.current.render(sceneRef.current, cameraRef.current);
  }

  /**
   * Creates the cloud layer, adds Mouse Listeners to the canvas and defines animation function
   */
  const create3DModel = () => {
    // Erstelle eine Kugelgeometrie
    const geometry = new THREE.SphereGeometry(1, 32, 32);

    // Erstelle ein Mesh-Objekt mit der Geometrie und dem Material
    earthMeshRef.current = new THREE.Mesh(geometry, material);
    cloudMeshRef.current = new THREE.Mesh(cloudGeometry, cloudMaterial);
    sceneRef.current.add(earthMeshRef.current);
    sceneRef.current.add(cloudMeshRef.current);
    containerRef.current.appendChild(canvasRef.current);

  }

  const initCanvas = () => {

    canvasRef.current = rendererRef.current.domElement;

    // TODO: should rotation stop when selecting country or only when dragging/clicking on non-country-area ?
    /*     //cancel mouse movement when rotating globe
        canvasRef.current.addEventListener('mousedown', function(event) {
          animationSpeed = 0;
        }) */

    //right-click listener for restarting the rotation animation after drag event
    canvasRef.current.addEventListener("contextmenu", function (event) {
      // Verhindert das Öffnen des Kontextmenüs
      event.preventDefault();
      animationSpeed = 0.0005;
    });

    raycasterRef.current = new THREE.Raycaster();
    canvasRef.current.addEventListener('mousemove', handleMouseMove);
    canvasRef.current.addEventListener('mousemove', handleMouseHover);
    canvasRef.current.addEventListener('click', handleMouseClick);
  }


  const handleMouseMove = (event) => {
    // Update the mouse position for raycasting
    const canvasBounds = canvasRef.current.getBoundingClientRect();
    const x = (event.clientX - canvasBounds.left) / canvasBounds.width * 2 - 1;
    const y = -(event.clientY - canvasBounds.top) / canvasBounds.height * 2 + 1;
    mouseRef.current.set(x, y);
  };

  /**
   * Handling of the mouse hover motion over the earth's surface for listening on intersection with locationMeshes
   * @param {} event 
   */
  const handleMouseHover = (event) => {
    raycasterRef.current.setFromCamera(mouseRef.current, cameraRef.current);
    const intersects = raycasterRef.current.intersectObjects(currentLocationMeshesRef.current, false);

    if (intersects.length > 0) {
      const currentMesh = intersects[0].object;
      if(locationMeshVisible(cameraRef.current, currentMesh)) {
        if (hoveredMesh.current && hoveredMesh.current !== currentMesh) {
          hoveredMesh.current.scale.set(1, 1, 1);
          earthMeshRef.current.add(hoveredMesh.current);
        }
        earthMeshRef.current.remove(currentMesh);
        // Increase size of currently hovered mesh
        currentMesh.scale.set(1.3, 1.3, 1.3);
        earthMeshRef.current.add(currentMesh);
        hoveredMesh.current = currentMesh;
        // Make cursor a pointer instead of default arrow
        containerRef.current.classList.add('pointer-cursor');
  
        // Show country name of currently hovered mesh next to cursor
        const hoverText = document.getElementById('hoverText');
        hoverText.innerText = currentMesh.country;
        hoverText.style.display = 'block';
        hoverText.style.left = (event.clientX + 15) + 'px';
        hoverText.style.top = (event.clientY - 65) + 'px';
      }
    } else {
      earthMeshRef.current.remove(currentLocationMeshesRef.current);

      if (hoveredMesh.current) {
        hoveredMesh.current.scale.set(1, 1, 1);
        earthMeshRef.current.add(hoveredMesh.current);
        hoveredMesh.current = null;
      }
      // Switch cursor symbol back to default again
      containerRef.current.classList.remove('pointer-cursor');

      // Remove hover text (country name)
      const hoverText = document.getElementById('hoverText');
      hoverText.style.display = 'none';
    }
  };

  /**
   * Selects location when clicking on a mesh. Stops rotation when clicking anywhere else on earthMesh
   * @param {*} event 
   */
  const handleMouseClick = (event) => {
    raycasterRef.current.setFromCamera(mouseRef.current, cameraRef.current);
    const intersects = raycasterRef.current.intersectObjects(currentLocationMeshesRef.current, false);

    if (intersects.length > 0) {
      const currentMesh = intersects[0].object;
      if(locationMeshVisible(cameraRef.current, currentMesh)) {
        setSelectedLocation(currentMesh.country.replace(/\s/g, ''));
        onUpdateSelectedLocationCallback(currentMesh.country.replace(/\s/g, ''));
        earthMeshRef.current.remove(currentLocationMeshesRef.current);

        if (hoveredMesh.current) {
          hoveredMesh.current.scale.set(1, 1, 1);
          earthMeshRef.current.add(hoveredMesh.current);
          hoveredMesh.current = null;
        }
      }
    } else {
      animationSpeed = 0;
    }
  };

  function locationMeshVisible(camera, mesh) {
    // Erstelle einen neuen Raycaster vom Kameraposition zum getroffenen Mesh
    const rayFromCameraToMesh = new THREE.Raycaster(camera.position, mesh.position.clone().sub(camera.position).normalize());
    
    // Führe den Raycast durch und erhalte die Intersections
    const intersections = rayFromCameraToMesh.intersectObjects(sceneRef.current.children, true);
  
    // Wenn es keine Intersections gibt, gibt es keine Hindernisse
    return intersections[0].object === mesh || intersections[1].object === mesh;
  }

  /**
   * Translates given lattitude and longitude into x,y and z coordinates
   */
  function latLonToCart(userLat, userLng) {
    const lat = (90 - userLat) * (Math.PI / 180);
    const lng = (userLng + 180) * (Math.PI / 180);

    const x = -(Math.sin(lat) * Math.cos(lng));
    const z = (Math.sin(lat) * Math.sin(lng));
    const y = Math.cos(lat);
    return [x, y, z];
  }

  /**
   * Effect for triggering the Initial GDelt Request (Maybe one can delete being triggered on selectedValue change)
   */
  useEffect(() => {
    if (!modelCreatedRef.current) {
      setup3DModel();
      create3DModel();
      modelCreatedRef.current = true;
    }
    removeExistingLocations();
    setSelectedValue(selectedTopic);
    initCanvas();
    cancelAnimationFrame(animationRef.current);
    animate();
  }, [selectedTopic, mentioningThreshold, selectedLocation, timespanNumber, timespanUnit, isMobile, currentLocationMeshesRef.current]);

  useEffect(() => {
    getArticles();
    getArticleCounts();
  }, [currentLocationMeshesRef.current, selectedLocation, timespanNumber, timespanUnit, isMobile]);

  useEffect(() => {
    getPrice();
  }, [selectedTopic, currentLocationMeshesRef.current]
  )

/**
 * Effect for triggering the timed GDelt Request every 15 minutes
 */   
useEffect(() => {
  const fetchData = async () => {
    removeExistingLocations();
    initCanvas();
    /* setSelectedValue(selectedTopic); */
/*     let oldArticles = [...currentArticles]; */
    await getArticles(); // Hier auf den Abschluss von getArticles() warten
    /* let newArticles = currentArticles.filter((article) => !oldArticles.includes(article));
    // Alle unique sourceCountry-Werte aus newArticles sammeln
    let uniqueSourceCountries = [...new Set(newArticles.map((article) => article.sourcecountry))];
    console.log("update");

    // Farbe 1.5 Sekunden lang auf Grün setzen
    currentLocationMeshesRef.current.forEach((mesh) => {
      if (uniqueSourceCountries.includes(mesh.country)) {
        mesh.material.color.set('#61ff69');
      } else {
        // Andernfalls setze die Farbe auf eine andere Standardfarbe
        mesh.material.color.set(colors.locationColorNew);
      }
    });

    setTimeout(() => {
      // Langsamer Übergang von Grün (#61ff69) zu Weiß
      const transitionDuration = 1500; // 1,5 Sekunden
      const transitionSteps = 60; // Anzahl der Schritte für den Übergang
      const colorStepR = (255 - 97) / transitionSteps; // Änderung in Rot von 97 (Grün) zu 255 (Weiß)
      const colorStepG = (255 - 255) / transitionSteps; // Änderung in Grün von 255 (Grün) zu 255 (Weiß)
      const colorStepB = (255 - 105) / transitionSteps; // Änderung in Blau von 105 (Grün) zu 255 (Weiß)
      let step = 0;

      let transitionInterval = setInterval(() => {
        const r = Math.round(97 + colorStepR * step);
        const g = Math.round(255 + colorStepG * step);
        const b = Math.round(105 + colorStepB * step);
        const colorString = `rgb(${r}, ${g}, ${b})`;

        currentLocationMeshesRef.current.forEach((mesh) => {
          if (uniqueSourceCountries.includes(mesh.country)) {
            mesh.material.color.set(colorString);
          }
        });

        step++;
        if (step > transitionSteps) {
          clearInterval(transitionInterval);
        }
      }, transitionDuration / transitionSteps);
    }, 1500); // Warte 1,5 Sekunden, bevor der Übergang beginnt */

    const nextExecutionTime = calculateNextExecutionTime();
    const timeUntilNextExecution = nextExecutionTime - Date.now();
    const formattedTime = formatMillisecondsToMinutesAndSeconds(timeUntilNextExecution);
    console.log(`Time until new Articles: ${formattedTime}`);
    console.log("Next auto fetch at: " + nextExecutionTime);
    setNewArticlesCountdown(formattedTime);

    if (timeUntilNextExecution > 0) {
      setTimeout(() => {
        fetchData();
        const interval = setInterval(fetchData, 15 * 60 * 1000);

        return () => {
          clearInterval(interval);
          clearTimeout(fetchTimeout);
        };
      }, timeUntilNextExecution);
    }
  };

  const fetchTimeout = setTimeout(fetchData, 0); // Starten Sie den initialen Datenabruf sofort

  return () => {
    clearTimeout(fetchTimeout); // Löschen des initialen Timeouts bei der Bereinigung
  };
}, []);

// Berechnen Sie die nächste geplante Ausführungszeit
const calculateNextExecutionTime = () => {
  const now = new Date();
  const minutes = now.getMinutes();
  const remainingMinutes = [5, 20, 35, 50].filter(min => min > minutes);

  if (remainingMinutes.length > 0) {
    return new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), remainingMinutes[0]);
  } else {
    const nextHour = now.getHours() + 1;
    return new Date(now.getFullYear(), now.getMonth(), now.getDate(), nextHour, 5);
  }
};

const formatMillisecondsToMinutesAndSeconds = milliseconds => {
  const totalSeconds = Math.floor(milliseconds / 1000);
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;
  
  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};



  /**
   * GDelt Request with selected currency
   */
  const getArticles = async () => {
    const articles = await fetchArticlesFromGdelt(mentioningThreshold, selectedTopic, selectedTopicAlt, timespanNumber, timespanUnit);
    setCurrentArticles(articles);
    for (let i = 0; i < articles.length; i++) {
      if (articles[i].latitude == null) {
        getCoordinatesByCountry(articles[i]);
      }
      
      let articleSourceCountry = articles[i].sourcecountry !== '' ? articles[i].sourcecountry : 'no country'; 

      if (currentLocationMeshesRef.current.some((mesh) => mesh.country === articleSourceCountry)) {
        continue;
      } else {
        createLocationMarker(articles[i]);
      }
    }
  };

  const getArticleCounts = async () => {
    if (selectedTopic != null) {
      const dateDays = await fetchArticleCountsFromGdelt(mentioningThreshold, selectedTopic, selectedTopicAlt, selectedLocation);
      if(dateDays === null) {
        setTodaysArticleCount('not available for "nocountry"');
        setYesterdaysArticleCount(0);
        return;
      }
      setTodaysArticleCount(dateDays[dateDays.length - 1].value);
      setYesterdaysArticleCount(dateDays[dateDays.length - 2].value);
    }
  }

  const getPrice = async () => {
    const topic = selectedTopic.toLowerCase();
    const data = await fetchPriceFromCoinGecko(topic);
    if(data[topic]) {
      const coinPrice = data[topic].usd;
      setCurrentPrice(coinPrice);
      setTwentyFourHoursChange(data[topic].usd_24h_change);
    } else {
      setCurrentPrice("no data");
      setTwentyFourHoursChange(0);
    }
  };

  /**
   * Fetches the coordinates for a given article's source country
   * @param {*} article the article for whose source country the coordinates are to be fetched
   */
  const getCoordinatesByCountry = (article) => {
    let coordinates = coordinateStorage.getCoordinatesForCountry(article.sourcecountry);
    article.latitude = coordinates.lat;
    article.longitude = coordinates.lon;
  }

  /**
   * Creates a location marker at the coordinates of the given article
   * @param {*} article The article for whose source country a location marker should be displayed
   */
const createLocationMarker = (article) => {
  const locationGeometry = new THREE.SphereGeometry(0.02, 20, 20);
  const locationMaterial = new THREE.MeshBasicMaterial({ color: colors.locationColorNew });
  const locationMesh = new LocationSphereMesh(locationGeometry, locationMaterial);

  // Verkleinere den Skalierungsfaktor, um die Position näher am Zentrum der Erde zu verschieben
  const scaleFactor = article.sourcecountry !== '' ? 0.997 : 1.07;
  const translatedCoordinates = article.sourcecountry === '' ? latLonToCart(90, 0) : latLonToCart(article.latitude, article.longitude);

  locationMesh.position.set(
    translatedCoordinates[0] * scaleFactor,
    translatedCoordinates[1] * scaleFactor,
    translatedCoordinates[2] * scaleFactor
  );
  locationMesh.country = article.sourcecountry === '' ? "no country" : article.sourcecountry;

  const ringGeometry = new THREE.RingGeometry(0.03, 0.04, 32); // Innere und äußere Radiuswerte anpassen
  const ringMaterial = new THREE.MeshBasicMaterial({ color: colors.locationColorNew, side: THREE.DoubleSide });
  const ringMesh = new THREE.Mesh(ringGeometry, ringMaterial);

  // Positioniere den Ring seitlich aus dem Location Mesh
  const distanceFromCenter = 0.004;
  const ringPosition = locationMesh.position.clone().normalize().multiplyScalar(scaleFactor + distanceFromCenter);
  ringMesh.position.copy(ringPosition);

  // Richte den Ring orthogonal in Richtung des Earth Mesh-Zentrums aus
  const earthCenter = new THREE.Vector3(0, 0, 0); // Annahme: Zentrum des Earth Mesh ist der Ursprung
  const direction = earthCenter.clone().sub(locationMesh.position).normalize();
  ringMesh.lookAt(ringMesh.position.clone().add(direction));

  if(locationMesh.country.replace(/\s/g, '') === selectedLocation) {
    ringMesh.geometry.scale(1.4, 1.4, 1.4);
  }

  if (locationMesh.country === 'no country') {
    //Activate to display no country locationMesh sideways of the earthMesh
    /* locationMesh.position.set(
      - 0.9,
      1,
      0.1
    ); */
    earthMeshRef.current.add(locationMesh);
  } else {
    earthMeshRef.current.add(locationMesh);
    earthMeshRef.current.add(ringMesh);
  }

  currentLocationMeshesRef.current.push(locationMesh);
  currentLocationRingMeshesRef.current.push(ringMesh);
};

  /**
   * Removes existing location meshes (currentLocationMeshes) when the user changed the currency (selectedValue)
   */
  const removeExistingLocations = () => {
    currentLocationMeshesRef.current.forEach((mesh) => {
      if (mesh instanceof THREE.Mesh) {
        mesh.geometry.dispose();
        mesh.material.dispose();
        earthMeshRef.current.remove(mesh);
      }
    });

    currentLocationMeshesRef.current = [];

    currentLocationRingMeshesRef.current.forEach((mesh) => {
      if (mesh instanceof THREE.Mesh) {
        mesh.geometry.dispose();
        mesh.material.dispose();
        earthMeshRef.current.remove(mesh);
      }
    });

    currentLocationRingMeshesRef.current = [];
  };

  const setContainerFullsize = () => {
    setFullScreen(!fullScreen);
  }


  return (
    <div>

    {!isMobile && (<div className='main-class'>
        <div className='globe-section'> 
          <div className='canvas-section' ref={containerRef}></div>
          {!isMobile && (<div>
            <div className='currency-section'>
              <CurrencyComponent selectedValue={selectedValue} selectedTopicAlt={selectedTopicAlt} currentPrice={currentPrice} todaysArticleCount={todaysArticleCount} yesterdaysArticleCount={yesterdaysArticleCount} twentyFourHoursChange={twentyFourHoursChange} todaysAvgTone={todaysAvgTone} yesterdaysAvgTone={yesterdaysAvgTone} newArticlesCountdown={newArticlesCountdown} isGodmode={isGodmode}></CurrencyComponent>
            </div>
            <div className={`articles-section ${fullScreen ? 'fullscreen-section' : ''}`}>
              <ArticlesComponent selectedTopic={selectedTopic} selectedTopicAlt={selectedTopicAlt} selectedLocation={selectedLocation} mentioningThreshold={mentioningThreshold} timespanNumber={timespanNumber} timespanUnit={timespanUnit} onSelectedLocationSet={() => {setSelectedLocation('global'); onUpdateSelectedLocationCallback('global');}} onFullSizeSet={() => setContainerFullsize()}></ArticlesComponent>
            </div>
          </div> )}
          <div id="hoverText"></div>
        </div>
    </div>
    )}
    {isMobile && (<div>
          <div className='mobile-container'>
            <div>
              <MobileArticlesComponent articles={currentArticles} selectedTopic={selectedTopic} selectedTopicAlt={selectedTopicAlt} selectedLocation={selectedLocation} mentioningThreshold={mentioningThreshold} timespanNumber={timespanNumber} timespanUnit={timespanUnit}></MobileArticlesComponent>
            </div>
            <div>
              <MobileCurrencyComponent selectedValue={selectedValue} currentPrice={currentPrice} todaysArticleCount={todaysArticleCount} yesterdaysArticleCount={yesterdaysArticleCount} twentyFourHoursChange={twentyFourHoursChange} todaysAvgTone={todaysAvgTone} yesterdaysAvgTone={yesterdaysAvgTone} newArticlesCountdown={newArticlesCountdown}></MobileCurrencyComponent>
            </div>

          </div>

    </div> )}
    </div>
  );
}

export default MainComponent;

