class DateConverter {
  /**
   * Converts a Date from GDELT-Format (YYYYMMDDTXXXX) to YYYY/MM/DD
   * @param {*} date The date in GDELT-Format which should be formatted
   * @returns The formatted date
   */
  static getFormattedDate = (date) => {
    const indexOfT = date.indexOf("T");
    var formattedDate = "";
    if (indexOfT !== -1) {
      formattedDate = date.substring(0, indexOfT).trim();

      const year = formattedDate.substring(0, 4);
      const month = formattedDate.substring(4, 6);
      const day = formattedDate.substring(6);
      formattedDate = year + "/" + month + "/" + day;
    } else {
        // "T" wurde nicht im String gefunden, daher wird der ursprüngliche String zurückgegeben
        console.log(date);
    }
    return formattedDate;
  };
}

export default DateConverter;
