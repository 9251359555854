import axios from 'axios';

const API_KEY = 'AIzaSyB4yfensB3P-2gx5Y55elSy1rNdQBTfsyA';

export async function detectAndTranslate(text) {
  try {
    const url = 'https://translation.googleapis.com/language/translate/v2/detect';
    const response = await axios.post(url, null, {
      params: {
        q: text,
        key: API_KEY,
      },
    });

    const sourceLanguage = response.data.data.detections[0][0].language;
    
    if (sourceLanguage !== 'en') {
      const translateUrl = 'https://translation.googleapis.com/language/translate/v2';
      const translationResponse = await axios.post(translateUrl, null, {
        params: {
          q: text,
          target: 'en',
          key: API_KEY,
        },
      });
      
      const translatedText = translationResponse.data.data.translations[0].translatedText;
      return translatedText;
    }
    
    return text;
  } catch (error) {
    console.error('Error during translation:', error);
    return null;
  }
}

export default detectAndTranslate;
