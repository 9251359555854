import React, { useState } from 'react';
import './ArticlesComponent.scss';
import SelectedLocationArticlesComponent from './SelectedLocationArticlesComponent.js';
import FullscreenIconOutlined from '@mui/icons-material/FullscreenOutlined';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FlagEmojiUtil from './utils/FlagEmojiUtil';
import Tooltip from '@mui/material/Tooltip';

const ArticlesComponent = ({ selectedTopic, selectedTopicAlt, mentioningThreshold, selectedLocation, timespanNumber, timespanUnit, onSelectedLocationSet, onFullSizeSet, isGodmode }) => {
  const [fullScreen, setFullScreen] = useState(false);
  const [sortBy, setSortBy] = useState("relevancy")

  const setContainerFullsize = () => {
    setFullScreen(!fullScreen);
    onFullSizeSet();
  }

  const setSelectedLocation = () => {
    onSelectedLocationSet();
  }

  const handleSortByChange = (event) => {
    setSortBy(event.target.value);
  }

  return (
    <div className={`side-container ${fullScreen ? 'fullscreen-side-container' : ''}`}>
      <div className='side-container-label'>
        <div className='side-container-header'>
          <div className='sort-selection'>
            <FormControl variant="standard">
              <Select
                labelId="timespan"
                id="timespanSelect"
                value={sortBy}
                onChange={handleSortByChange}
                className='custom-select'
              >
                <MenuItem value={"relevancy"}>Relevancy</MenuItem>
                <MenuItem value={"latest"}>Latest</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div>
            <strong>ARTICLES</strong>
            <Tooltip title={<div className="flag-tooltip">{selectedLocation}</div>}>
              <div className="flag-emoji">
                <FlagEmojiUtil countryCode={selectedLocation !== '' ? selectedLocation : 'global'} />
              </div>
            </Tooltip>
          </div>
          <div className='fullscreen-box'>
            {fullScreen ? (
              <FullscreenExitIcon className='fullscreen-button' onClick={() => setContainerFullsize(false)} />
            ) : (
              <FullscreenIconOutlined className='fullscreen-button' onClick={() => setContainerFullsize(true)} />
            )}
          </div>
        </div>
      </div>
      <div className='side-container-content'>
        <SelectedLocationArticlesComponent selectedTopic={selectedTopic} selectedTopicAlt={selectedTopicAlt} mentioningThreshold={mentioningThreshold} selectedLocation={selectedLocation} timespanNumber={timespanNumber} timespanUnit={timespanUnit} fullScreen={fullScreen} sortBy={sortBy}></SelectedLocationArticlesComponent>
      </div>
      <div className='reset-container'>
        <button className='reset-button' onClick={() => setSelectedLocation()}>Reset</button>
      </div>
    </div>
  );

};



export default ArticlesComponent;
