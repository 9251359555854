import React, { useState, useEffect, useRef } from "react";
import { FaBars } from "react-icons/fa";
import "./MobileNavigationBar.scss";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { TextField } from "@mui/material";
import Grid from '@mui/material/Grid';
import Box from "@mui/material/Box";
import Typography from '@mui/material/Typography';





const numericInputPropsMentioningThreshold = {
  sx: {
    color: 'white',
    textAlign: 'center',
    fontSize: '20px',
  },
  inputMode: 'numeric',
  pattern: '[0-9]*',
  min: 1,
  max: 5,
};

const numericInputPropsTimespan = {
  sx: {
    color: 'white',
    textAlign: 'center',
    fontSize: '20px',
  },
  inputMode: 'numeric',
  pattern: '[0-9]*',
  min: 1,
  max: 1000,
};

const textFieldStylingInputProps = {
  sx: {
    '& fieldset': {
      border: '1px solid white!important',
      borderRadius: '7px',
    },
    '&:hover fieldset': {
      border: '2px solid white!important',
      borderRadius: '7px',
    },
    '&:focus-within fieldset, &:focus-visible fieldset': {
      border: '2px solid white!important',
      borderRadius: '7px',
    },
  },
};

const labelInputProps = {
  sx: {
    color: 'white',
    '&.Mui-focused': {
      color: 'white',
      fontWeight: 'bold',
    },
  },
};


const MobileNavigationBar = ({ onSelectChangeTopic, onSelectChangeMentioningThreshold, onSelectChangeTimespanNumber, onSelectChangeTimespanUnit, title }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [topic, setTopic] = useState('');
  const [mentioningThreshold, setMentioningThreshold] = useState(1);
  const [timespanNumber, setTimespanNumber] = useState(1);
  const [timespanUnit, setTimespanUnit] = useState('d');
  const currenciesToCodeMap = useRef(new Map());

  useEffect(() => {
    const fetchCurrencies = async () => {
      try {
        const apiKey = '8c663e00-ac8e-4c49-9d3d-271f0f340c29';
        const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': apiKey,
          },
          body: JSON.stringify({
            currency: 'USD',
            sort: 'rank',
            order: 'ascending',
            offset: 0,
            limit: 100,
            meta: true,
          }),
        };

        const response = await fetch('https://api.livecoinwatch.com/coins/list', requestOptions);

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        //Store that data anywhere and only fetch new if its a new day or something
        const data = await response.json();
        data.forEach(element => {
          currenciesToCodeMap.current.set(element.name, element.code);
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchCurrencies();
  }, []);


  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleChangeTopic = (event) => {
    const selectedValue = event.target.value;
    setTopic(selectedValue);
    onSelectChangeTopic(selectedValue, currenciesToCodeMap.current.get(selectedValue));
  };

  const handleChangeMentioningThreshold = (event) => {
    let selectedValue = event.target.value;
    const numericValue = parseFloat(selectedValue);
  
    if (isNaN(numericValue)) {
      return;
    }
  
    // Max is 5
    if (numericValue > 5) {
      selectedValue = 5;
    }
  
    setMentioningThreshold(numericValue);
    onSelectChangeMentioningThreshold(numericValue);
  };

  const handleChangeTimespanNumber = (event) => {
    let selectedValue = event.target.value;
    if (selectedValue === '') {
      selectedValue = mentioningThreshold;
    }
    // Max is 1000
    if (selectedValue > 1000) {
      selectedValue = 1000;
    }
    setTimespanNumber(selectedValue);
    onSelectChangeTimespanNumber(selectedValue);
  };

  const handleChangeTimespanUnit = (event) => {
    const selectedValue = event.target.value;
    setTimespanUnit(selectedValue);
    onSelectChangeTimespanUnit(selectedValue);
  };


  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  
  return (
    <nav className="mobile-navigation-bar">
      <div className="mobile-burger-menu" onClick={toggleMenu}>
        <FaBars />
      </div>
      <div className="mobile-title" onClick={scrollToTop}>
        {title}
      </div>
      <div className={`mobile-sidebar ${showMenu ? "show" : ""}`}>
        <FormControl style={{ marginTop: '10px', width: '90%' }}>
          <InputLabel className="mobile-custom-inputlabel" id="currency">
            Currency
          </InputLabel>
          <Select
            labelId="currency"
            id="currency"
            label="currency"
            value={topic}
            onChange={handleChangeTopic}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: `30vh`,
                },
              },
            }}
            className="mobile-custom-select"
          >
            {
              Array.from(currenciesToCodeMap.current.keys()).map((currency) => (
                <MenuItem key={currency} value={currency}>
                  {currency} ({currenciesToCodeMap.current.get(currency)})
                </MenuItem>
              ))
            }
          </Select>
        </FormControl>
        <div style={{ marginTop: '20px' }}></div>
        <FormControl style={{ width: '90%' }}>
          <TextField
            type="number"
            label="Mentioning Threshold"
            inputProps={numericInputPropsMentioningThreshold}
            InputProps={textFieldStylingInputProps}
            InputLabelProps={labelInputProps}
            value={mentioningThreshold}
            onChange={handleChangeMentioningThreshold}
            className="mobile-textfield"
          />
        </FormControl>
        <Grid container spacing={2} style={{ marginTop: '0px' }}>
          <Grid item xs={12}>
            <Grid container justifyContent="center">
              <Box className="mobile-custombox">
                <Typography variant="subtitle1" className="custom-typography">
                  Timespan
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <FormControl style={{ marginTop: '20px', height: '100%' }}>
                      <TextField
                        className="mobile-timespan-number"
                        type="number"
                        inputProps={numericInputPropsTimespan}
                        InputProps={textFieldStylingInputProps}
                        InputLabelProps={labelInputProps}
                        value={timespanNumber}
                        onChange={handleChangeTimespanNumber}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl style={{ marginTop: '20px', width: '100%', height: '100%' }}>
                      <Select className="mobile-custom-select"
                        labelId="timespan"
                        id="timespanSelect"
                        value={timespanUnit}
                        onChange={handleChangeTimespanUnit}
                      >
                        <MenuItem value={"min"}>minute(s)</MenuItem>
                        <MenuItem value={"h"}>hour(s)</MenuItem>
                        <MenuItem value={"d"}>day(s)</MenuItem>
                        <MenuItem value={"w"}>week(s)</MenuItem>
                        <MenuItem value={"m"}>month(s)</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </nav>
  );
};

export default MobileNavigationBar;
