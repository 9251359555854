// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "", "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"baseColor": "#121212",
	"accentColor": "#fff",
	"accentColorTwo": "#222",
	"chartBackground": "#121212",
	"articlesBackground": "#353535",
	"sectionTitleColor": "#fff",
	"white": "#fff",
	"black": "#000",
	"hoverFontColor": "#eee",
	"lighterAccentColorTwo": "#353535",
	"darkerAccentColor": "#dddddd",
	"chartContentColor": "#47adad",
	"footerBackground": "#040404",
	"sidebarBackground": "#161616",
	"visitedLinkColor": "#999",
	"green": "#61ff69",
	"red": "#ff6961",
	"locationColor": "#4fc2b7",
	"locationColorNew": "#fff"
};
export default ___CSS_LOADER_EXPORT___;
