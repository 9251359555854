import React, { useState, useEffect } from 'react';
import './CurrencyComponent.scss';
import UpdateIcon from '@mui/icons-material/Update';
import Tooltip from '@mui/material/Tooltip';
import colors from './Colors.scss';
import './ArticlesComponent.scss';
import InfoIcon from '@mui/icons-material/Info';

const CurrencyComponent = ({ selectedValue, selectedTopicAlt, currentPrice, todaysArticleCount, yesterdaysArticleCount, twentyFourHoursChange, todaysAvgTone, yesterdaysAvgTone, newArticlesCountdown, isGodmode }) => {
    const [articleUpdateCountdown, setArticleUpdateCountdown] = useState(0); // In Sekunden

    useEffect(() => {
        const [minutes, seconds] = newArticlesCountdown.split(':');
        const totalSeconds = parseInt(minutes) * 60 + parseInt(seconds);
        setArticleUpdateCountdown(totalSeconds); // Umwandlung in Sekunden
        const interval = setInterval(() => {
            setArticleUpdateCountdown(prevCountdown => prevCountdown - 1);
        }, 1000); // Aktualisiere jede Sekunde

        return () => {
            clearInterval(interval);
        };
    }, [newArticlesCountdown]);


    /**
     * 
     * @param {number} amountOld The old value
     * @param {number} amountNew 
     * @returns 
     */
    const calculatePercentageChange = (amountOld, amountNew) => {
        const percentageChange = ((amountNew / amountOld) - 1) * 100;
        return percentageChange.toFixed(1);
    };

    return (

        <div className='side-container'>
            <div className='side-container-label'>
                <div className='side-container-header'>
                    <div className='left-spacer'>
                    </div>
                    <div>
                        <strong><strong>{selectedValue.toUpperCase()}</strong></strong>
                        <div className="crypto-icon">
                            {isGodmode !== true && (
                                <img
                                    className='crpyto-icon'
                                    src={`https://cryptologos.cc/logos/${selectedValue.toLowerCase().replace(/\s+/g, '-')}-${selectedTopicAlt.toLowerCase()}-logo.png?v=026`}
                                    alt={`${selectedValue.toLowerCase()} icon`}
                                />
                            )}
                        </div>
                    </div>
                    <div className='fullscreen-box'>
                        <Tooltip
                            title={
                                <div className="currency-component-explanation-tooltip">
                                    <div>
                                        <strong>Todays article count:</strong> The term 'article count' refers to the number of distinct articles that match your search terms (currency, timespan, mentioning threshold). It signifies the quantity of unique articles that meet the specified criteria, rather than a simple count of all published articles.
                                    </div>
                                    <br/>
                                    <div>
                                        <strong>Average Tone:</strong> The tone indicator represents the emotional sentiment or tone of articles, typically ranging from -100 (extremely negative) to +100 (extremely positive), although the actual range typically falls within -20 to 20 or less. Articles in the -1 to +1 bin tend to be more neutral or factually-focused, while those on either extreme tend to be emotionally-laden diatribes. This parameter provides a way to quantify and categorize the emotional content of written or spoken language, helping to gauge the sentiment expressed in the content. It can be a valuable tool for analyzing and understanding the emotional characteristics of textual data.
                                    </div>
                                </div>
                            }
                        >
                            <InfoIcon className='info-icon' />
                        </Tooltip>
                    </div>
                </div>
            </div>
            <div className='description-container'>
                <div className='stats-container'>
                    {todaysArticleCount !== null && (
                        <div>
                            Todays article count: {todaysArticleCount}{" "}
                            <div
                                style={{
                                    color: calculatePercentageChange(yesterdaysArticleCount, todaysArticleCount) < 0 ? colors.red : colors.green,
                                }}
                            >
                                {calculatePercentageChange(yesterdaysArticleCount, todaysArticleCount) < 0 ? "↓" : "↑+"}
                                {calculatePercentageChange(yesterdaysArticleCount, todaysArticleCount)}%
                            </div>
                        </div>
                    )}
                    {isGodmode !== true && (
                        <div>
                            Price: {currentPrice}${" "}
                            <div
                                style={{
                                    color: twentyFourHoursChange < 0 ? colors.red : colors.green,
                                }}
                            >
                                {twentyFourHoursChange < 0 ? "↓" : "↑+"}
                                {twentyFourHoursChange ? twentyFourHoursChange.toFixed(1) : "-"}%
                            </div>
                        </div>
                    )}
                    <div>
                        Average Tone:  {todaysAvgTone}{" "}
                        <div
                            style={{
                                color: (todaysAvgTone - yesterdaysAvgTone) < 0 ? colors.red : colors.green,
                            }}
                        >
                            {(todaysAvgTone - yesterdaysAvgTone) < 0 ? "↓" : "↑+"}
                            {(todaysAvgTone - yesterdaysAvgTone).toFixed(2)}
                        </div>
                    </div>
                </div>
                <div>
                    <Tooltip title="Time until new Auto-Update">
                        <div className='countdown-icon-container'>
                            <UpdateIcon className='countdown-icon' />
                        </div>
                    </Tooltip>{" "}
                    <div className='countdown-container'>
                        {articleUpdateCountdown >= 0 ? `${Math.floor(articleUpdateCountdown / 60)}:${(articleUpdateCountdown % 60).toString().padStart(2, '0')}` : "0:00"}
                    </div>
                </div>
            </div>
        </div>
    );

};



export default CurrencyComponent;
