import React, { useState, useEffect, useRef } from 'react';
import PublicIcon from '@mui/icons-material/Public';
import TranslateIcon from '@mui/icons-material/Translate';
import LinkIcon from '@mui/icons-material/Link';
import ScheduleIcon from '@mui/icons-material/Schedule';
import {Button, Grid} from '@mui/material';
import './SelectedArticles.scss';
import DateConverter from './DateConverter';
import detectAndTranslate from './utils/TranslationUtil';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';

const SelectedArticle = ({selectedArticle, fullScreen}) => {
// fully functional translator but we need free api
// useEffect(() => {
//   // Function to translate the initial text when the component mounts
//   const translateInitialText = async () => {

//     try {

      
//       const translated = await detectAndTranslate('hallo du stinktier');
//       if (translated) {
//         // setTranslatedText(translated);
//         console.log(translated);
//       } else {
//         // Handle translation error if needed
//       }
//     } catch (error) {
//       console.error('Error during translation: ', error);
//     }
//   };

//   translateInitialText();
// }, []);

const getDomainWithProtocol = () => {
  const url = selectedArticle.url;
  const protocol = new URL(url).protocol + "//";
  const domainWithProtocol = protocol + "www." + selectedArticle.domain;
  return domainWithProtocol;
}


  return (
    <Grid className='articles-container' container>
      {selectedArticle && (
        <>
          <Grid className={fullScreen ? 'flex-start' : 'flex-center'} item xs={3}>
            <a href={selectedArticle.url} target='_blank'>
              <img className={fullScreen ? 'image-fullscreen' : ''} width="50px" height="50px" src={selectedArticle.socialimage} onError={(e) => e.target.src = 'https://placehold.co/50x50.png?text=Placeholder&fontsize=12&font=roboto'} />
            </a>
          </Grid>
          <Grid className={fullScreen ? 'fullscreen-article' : 'flex-column'} item xs={8}>
            <a className={fullScreen ? 'article-url-fullscreen' : 'article-url'} target='_blank' href={selectedArticle.url}>{selectedArticle.title}</a>
            <Grid item xs={1}>
              <Tooltip
                title={
                  <div className="tooltip-content">
                    <div className="tooltip-icon-row">
                      <LinkIcon className={fullScreen ? 'tooltip-icon-big' : 'tooltip-icon-small'} />
                      <span className="tooltip-text">
                        <a className='no-decoration-url' target='_blank' href={getDomainWithProtocol()}>
                          {selectedArticle.domain}
                        </a>
                      </span>
                    </div>
                    <div className="tooltip-icon-row">
                      <ScheduleIcon className={fullScreen ? 'tooltip-icon-big' : 'tooltip-icon-small'} />
                      <span className="tooltip-text">
                        {DateConverter.getFormattedDate(selectedArticle.seendate)}
                      </span>
                    </div>
                    <div className="tooltip-icon-row">
                      <TranslateIcon className={fullScreen ? 'tooltip-icon-big' : 'tooltip-icon-small'} />
                      <span className="tooltip-text">
                        {selectedArticle.language}
                      </span>
                    </div>
                    <div className="tooltip-icon-row">
                      <PublicIcon className={fullScreen ? 'tooltip-icon-big' : 'tooltip-icon-small'} />
                      <span className="tooltip-text">
                        {selectedArticle.sourcecountry ? selectedArticle.sourcecountry : ""}
                      </span>
                    </div>
                  </div>
                }
              >
                <InfoIcon className={fullScreen ? 'icon-big' : 'icon-small'} />
              </Tooltip>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );

};



export default SelectedArticle;
