export async function fetchPriceFromCoinGecko(topic) {
    try {
        const response = await fetch(
          `https://api.coingecko.com/api/v3/simple/price?ids=${topic}&&include_24hr_change=true&precision=2&vs_currencies=usd`
        );
        const data = await response.json();
        return data;
      } catch (error) {
        console.error('Error fetching price:', error);
      }
};
