import React, { useState } from 'react';
import './ManualComponent.scss';

function ManualComponent() {
    
    const [isOpen, setIsOpen] = useState(false);

    const handleOpen = () => {
        setIsOpen(!isOpen);
    };
  
    const handleClose = () => {
        setIsOpen(false);
    };
  
    return (
      <div>
          <div onClick={handleOpen} className="manual-header">Manual</div>
          {isOpen && (
              <div className="manual-container">
                  <div className="manual-title">Cointernational User Manual</div>
                  <div className="manual-content">
                    <h2>Overview</h2>
                    <p>Welcome to <strong>Cointernational</strong>! This application is designed to help you stay informed about cryptocurrencies and their relationship with global news events. With this app, you can:</p>
                    <ul>
                        <li><strong>Fetch Data:</strong> Access real-time cryptocurrency data from the GDELT database.</li>
                        <li><strong>Visualize Data:</strong> See cryptocurrency-related news events on a 3D rendered globe.</li>
                        <li><strong>Filter Data:</strong> Customize your data view by choosing a specific cryptocurrency, a time frame, and a mentioning threshold.</li>
                    </ul>

                    <h2>Fetching Data</h2>
                    <ul>
                        <li><strong>Using GDELT:</strong> Our app connects to the GDELT database to retrieve real-time data.</li>
                        <li>Every 15 minutes, our application automatically initiates a new request to fetch recently scanned articles. You can check the timer at the bottom of the currency container on the left side.</li>
                    </ul>

                    <h2>Visualizing Data</h2>
                    <ul>
                        <li><strong>3D Rendered Globe:</strong> Explore a 3D globe that displays cryptocurrency data points and news event markers.</li>
                        <li><strong>Left Click/Drag:</strong> Drag the mouse to spin the globe. The globe's auto-rotation will be suspended when dragging.</li>
                        <li><strong>Right Click:</strong> Performing a right-click will resume the globe's auto-rotation.</li>
                        <li><strong>Cryptocurrency Data Points:</strong> See cryptocurrency-specific data on the globe.</li>
                        <li><strong>News Events Markers:</strong> Identify news events and their potential impact on cryptocurrencies.</li>
                    </ul>

                    <h2>Using the Application</h2>
                    <p>With <strong>Cointernational</strong>, you can explore cryptocurrency news events around the world and access detailed information about specific locations and currencies:</p>
                    <ul>
                        <li><strong>Explore News by Location:</strong> Click on a location marker on the globe to see recent articles about the cryptocurrency in that specific country.</li>
                        <li><strong>Filter News:</strong> Use the filter system to refine your results by curreny, relevance, mentioning threshold (a limit that determines how often the selected currency must be mentioned in the article), and time.</li>
                        <li><strong>View General Information:</strong> On the left side of the screen, you'll always find general information about the selected currency, including its current price and news count. There's also a timer indicating when new articles will be fetched.</li>
                        <li><strong>Toggle Filters:</strong> Press the burger button to access our filter system and adjust the currency and time filters.</li>
                        <li><strong>Explore Charts:</strong> Scroll down to discover multiple charts providing insights into the chosen location or currency. If no specific location or currency is selected, you'll see global data.</li>
                    </ul>

                    <h2>Support</h2>
                    <p>If you need assistance or have questions or even improvement suggestions, please contact our support team at <strong><a className='contact-mail' href="mailto:pajuprojects@gmail.com">pajuprojects@gmail.com</a></strong>.</p>

                    <p>That's it! Enjoy using <strong>Cointernational</strong> to stay informed about cryptocurrencies and global news events.</p>
                  </div>
                  <div className="manual-button-container">
                      <div className="close-manual-button" onClick={handleClose}>Close</div>
                  </div>
              </div>
          )}
      </div>
  );
}

export default ManualComponent;
