import React from 'react';
import twemoji from 'twemoji';

class FlagEmojiUtil {
  static getFlagEmoji(countryCode, size) {
    const flagMap = {
      'AFGHANISTAN': '🇦🇫',
      'ALBANIA': '🇦🇱',
      'ALGERIA': '🇩🇿',
      'ANDORRA': '🇦🇩',
      'ANGOLA': '🇦🇴',
      'ANTIGUAANDBARBUDA': '🇦🇬',
      'ARGENTINA': '🇦🇷',
      'ARMENIA': '🇦🇲',
      'AUSTRALIA': '🇦🇺',
      'AUSTRIA': '🇦🇹',
      'AZERBAIJAN': '🇦🇿',
      'BAHAMAS': '🇧🇸',
      'BAHRAIN': '🇧🇭',
      'BANGLADESH': '🇧🇩',
      'BARBADOS': '🇧🇧',
      'BELARUS': '🇧🇾',
      'BELGIUM': '🇧🇪',
      'BELIZE': '🇧🇿',
      'BENIN': '🇧🇯',
      'BHUTAN': '🇧🇹',
      'BOLIVIA': '🇧🇴',
      'BOSNIAANDHERZEGOVINA': '🇧🇦',
      'BOTSWANA': '🇧🇼',
      'BRAZIL': '🇧🇷',
      'BRUNEI': '🇧🇳',
      'BULGARIA': '🇧🇬',
      'BURKINAFASO': '🇧🇫',
      'BURUNDI': '🇧🇮',
      'CABOVERDE': '🇨🇻',
      'CAMBODIA': '🇰🇭',
      'CAMEROON': '🇨🇲',
      'CANADA': '🇨🇦',
      'CENTRALAFRICANREPUBLIC': '🇨🇫',
      'CHAD': '🇹🇩',
      'CHILE': '🇨🇱',
      'CHINA': '🇨🇳',
      'COLOMBIA': '🇨🇴',
      'COMOROS': '🇰🇲',
      'CONGO': '🇨🇬',
      'COSTARICA': '🇨🇷',
      'CÔTED\'IVOIRE': '🇨🇮',
      'CROATIA': '🇭🇷',
      'CUBA': '🇨🇺',
      'CYPRUS': '🇨🇾',
      'CZECHREPUBLIC': '🇨🇿',
      'DEMOCRATICREPUBLICOFTHECONGO': '🇨🇩',
      'DENMARK': '🇩🇰',
      'DJIBOUTI': '🇩🇯',
      'DOMINICA': '🇩🇲',
      'DOMINICANREPUBLIC': '🇩🇴',
      'ECUADOR': '🇪🇨',
      'EGYPT': '🇪🇬',
      'ELSALVADOR': '🇸🇻',
      'EQUATORIALGUINEA': '🇬🇶',
      'ERITREA': '🇪🇷',
      'ESTONIA': '🇪🇪',
      'ESWATINI': '🇸🇿',
      'ETHIOPIA': '🇪🇹',
      'FIJI': '🇫🇯',
      'FINLAND': '🇫🇮',
      'FRANCE': '🇫🇷',
      'GABON': '🇬🇦',
      'GAMBIA': '🇬🇲',
      'GEORGIA': '🇬🇪',
      'GERMANY': '🇩🇪',
      'GHANA': '🇬🇭',
      'GLOBAL': '🌎',
      'GREECE': '🇬🇷',
      'GRENADA': '🇬🇩',
      'GUATEMALA': '🇬🇹',
      'GUINEA': '🇬🇳',
      'GUINEA-BISSAU': '🇬🇼',
      'GUYANA': '🇬🇾',
      'HAITI': '🇭🇹',
      'HOLYSEE': '🇻🇦',
      'HONDURAS': '🇭🇳',
      'HUNGARY': '🇭🇺',
      'ICELAND': '🇮🇸',
      'INDIA': '🇮🇳',
      'INDONESIA': '🇮🇩',
      'IRAN': '🇮🇷',
      'IRAQ': '🇮🇶',
      'IRELAND': '🇮🇪',
      'ISRAEL': '🇮🇱',
      'ITALY': '🇮🇹',
      'JAMAICA': '🇯🇲',
      'JAPAN': '🇯🇵',
      'JORDAN': '🇯🇴',
      'KAZAKHSTAN': '🇰🇿',
      'KENYA': '🇰🇪',
      'KIRIBATI': '🇰🇮',
      'KOSOVO': '🇽🇰',
      'KUWAIT': '🇰🇼',
      'KYRGYZSTAN': '🇰🇬',
      'LAOS': '🇱🇦',
      'LATVIA': '🇱🇻',
      'LEBANON': '🇱🇧',
      'LESOTHO': '🇱🇸',
      'LIBERIA': '🇱🇷',
      'LIBYA': '🇱🇾',
      'LIECHTENSTEIN': '🇱🇮',
      'LITHUANIA': '🇱🇹',
      'LUXEMBOURG': '🇱🇺',
      'MADAGASCAR': '🇲🇬',
      'MALAWI': '🇲🇼',
      'MALAYSIA': '🇲🇾',
      'MALDIVES': '🇲🇻',
      'MALI': '🇲🇱',
      'MALTA': '🇲🇹',
      'MARSHALLISLANDS': '🇲🇭',
      'MAURITANIA': '🇲🇷',
      'MAURITIUS': '🇲🇺',
      'MEXICO': '🇲🇽',
      'MICRONESIA': '🇫🇲',
      'MOLDOVA': '🇲🇩',
      'MONACO': '🇲🇨',
      'MONGOLIA': '🇲🇳',
      'MONTENEGRO': '🇲🇪',
      'MOROCCO': '🇲🇦',
      'MOZAMBIQUE': '🇲🇿',
      'MYANMAR': '🇲🇲',
      'NAMIBIA': '🇳🇦',
      'NAURU': '🇳🇷',
      'NEPAL': '🇳🇵',
      'NETHERLANDS': '🇳🇱',
      'NEWZEALAND': '🇳🇿',
      'NICARAGUA': '🇳🇮',
      'NIGER': '🇳🇪',
      'NIGERIA': '🇳🇬',
      'NORTH KOREA': '🇰🇵',
      'NORTHMACEDONIA': '🇲🇰',
      'MACEDONIA' : '🇲🇰',
      'NORWAY': '🇳🇴',
      'OMAN': '🇴🇲',
      'PAKISTAN': '🇵🇰',
      'PALAU': '🇵🇼',
      'PALESTINESTATE': '🇵🇸',
      'PANAMA': '🇵🇦',
      'PAPUANEWGUINEA': '🇵🇬',
      'PARAGUAY': '🇵🇾',
      'PERU': '🇵🇪',
      'PHILIPPINES': '🇵🇭',
      'POLAND': '🇵🇱',
      'PORTUGAL': '🇵🇹',
      'QATAR': '🇶🇦',
      'ROMANIA': '🇷🇴',
      'RUSSIA': '🇷🇺',
      'RWANDA': '🇷🇼',
      'SAINTKITTSANDNEVIS': '🇰🇳',
      'SAINTLUCIA': '🇱🇨',
      'SAINTVINCENTANDTHEGRENADINES': '🇻🇨',
      'SAMOA': '🇼🇸',
      'SANMARINO': '🇸🇲',
      'SAOTOMEANDPRINCIPE': '🇸🇹',
      'SAUDIARABIA': '🇸🇦',
      'SENEGAL': '🇸🇳',
      'SERBIA': '🇷🇸',
      'SEYCHELLES': '🇸🇨',
      'SIERRALEONE': '🇸🇱',
      'SINGAPORE': '🇸🇬',
      'SLOVAKIA': '🇸🇰',
      'SLOVENIA': '🇸🇮',
      'SOLOMONISLANDS': '🇸🇧',
      'SOMALIA': '🇸🇴',
      'SOUTHAFRICA': '🇿🇦',
      'SOUTHKOREA': '🇰🇷',
      'SOUTHSUDAN': '🇸🇸',
      'SPAIN': '🇪🇸',
      'SRILANKA': '🇱🇰',
      'SUDAN': '🇸🇩',
      'SURINAME': '🇸🇷',
      'SWEDEN': '🇸🇪',
      'SWITZERLAND': '🇨🇭',
      'SYRIA': '🇸🇾',
      'TAIWAN': '🇹🇼',
      'TAJIKISTAN': '🇹🇯',
      'TANZANIA': '🇹🇿',
      'THAILAND': '🇹🇭',
      'TIMOR-LESTE': '🇹🇱',
      'TOGO': '🇹🇬',
      'TONGA': '🇹🇴',
      'TRINIDADANDTOBAGO': '🇹🇹',
      'TUNISIA': '🇹🇳',
      'TURKEY': '🇹🇷',
      'TURKMENISTAN': '🇹🇲',
      'TUVALU': '🇹🇻',
      'UGANDA': '🇺🇬',
      'UKRAINE': '🇺🇦',
      'UNITEDARABEMIRATES': '🇦🇪',
      'UNITEDKINGDOM': '🇬🇧',
      'UNITEDSTATES': '🇺🇸',
      'URUGUAY': '🇺🇾',
      'UZBEKISTAN': '🇺🇿',
      'VANUATU': '🇻🇺',
      'VENEZUELA': '🇻🇪',
      'VIETNAM': '🇻🇳',
      'YEMEN': '🇾🇪',
      'ZAMBIA': '🇿🇲',
      'ZIMBABWE': '🇿🇼'
    };
    
    const flagEmoji = flagMap[countryCode.toUpperCase()];

    if (!flagEmoji) {
      return `<span width: ${size}px; height: ${size}px;">🏴</span>`;
    }

    const emojiMarkup = twemoji.parse(flagEmoji, { folder: 'svg', ext: '.svg' });

    return `<span style="display: inline-block; width: ${size}px; height: ${size}px;">${emojiMarkup}</span>`;
  }
}

const CountryFlagEmoji = ({ countryCode, size = 24 }) => {
  const emojiMarkup = FlagEmojiUtil.getFlagEmoji(countryCode, size);

  return <div dangerouslySetInnerHTML={{ __html: emojiMarkup }} />;
};

export default CountryFlagEmoji;





