import React, { useState, useEffect, useRef } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import Chart from 'chart.js/auto'; // NEEDED, DON'T DELETE!!!
import './ToneTimelineComponent.scss';
import colors from './Colors.scss';
import DateConverter from './DateConverter';
import { fetchToneTimeLineDataFromGdelt } from './utils/GdeltUtil';

const ToneTimelineComponent = ({selectedTopic, selectedTopicAlt, mentioningThreshold, onAvgToneUpdateCallback, selectedLocation}) => {

    const [currentTimeline, setCurrentTimeline] = useState([]);
    const [currentDates, setCurrentDates] = useState([]);
    const [currentArticleCounts, setCurrentArticleCounts] = useState([]);
    const dateToAverageToneMap = useRef(new Map());
    const todaysAvgTone = useRef(0);
    const yesterdaysAvgTone = useRef(0);

    useEffect(() => {
        fetchTimeline();
        removeOldValues();
    }, [selectedTopic, mentioningThreshold, selectedLocation]);

    const fetchTimeline = async () => {
        if(selectedTopic != null) {
            const timeline = await fetchToneTimeLineDataFromGdelt(selectedTopic, selectedTopicAlt, selectedLocation, mentioningThreshold);
            if(timeline === null) {
              return;
            }
            setCurrentTimeline(timeline);
            const dateDays = timeline.data.timeline[0].data;
            todaysAvgTone.current = (dateDays[dateDays.length -1].value);
            yesterdaysAvgTone.current = (dateDays[dateDays.length -2].value);
            onAvgToneUpdateCallback(todaysAvgTone.current, yesterdaysAvgTone.current);
            for(let i = dateDays.length/2; i < dateDays.length; i++) {
                setCurrentDates((currentDates) => {
                    const updatedMeshes = [...currentDates, DateConverter.getFormattedDate(dateDays[i].date)];
                    return updatedMeshes;
                });
                setCurrentArticleCounts((currentDates) => {
                    const updatedMeshes = [...currentDates, dateDays[i].value];
                    return updatedMeshes;   
                });
                dateToAverageToneMap.current.set(DateConverter.getFormattedDate(dateDays[i].date), dateDays[i].value);
            }
        }
    }

    const dateLabels = Array.from(dateToAverageToneMap.current.keys());
    const articleCounts = Array.from(dateToAverageToneMap.current.values());

    function hexToRgbA(hex, alpha) {
      const r = parseInt(hex.slice(1, 3), 16);
      const g = parseInt(hex.slice(3, 5), 16);
      const b = parseInt(hex.slice(5, 7), 16);
      return `rgba(${r},${g},${b},${alpha})`;
    }


    const backgroundColor = hexToRgbA(colors.chartContentColor, 0.7);

    
    const data = {
      labels: dateLabels,
      datasets: [
        {
          label: 'Average Article Tone', 
          data: articleCounts,
          fill: false,
          backgroundColor: backgroundColor,
          borderRadius: 5,
          borderWidth: 2,
        },
      ],
    };
        
    const options = {
        scales: {
          y: {
            type: 'linear',
            beginAtZero: false,
            ticks: {
              color: colors.white,
              callback: (value, index, values) => {
                // Display every second y-axis label
                if (index % 2 === 0) {
                    return value;
                }
                return ''; // Hide labels for other indices
            },
            },
            grid: {
              color: 'rgba(255, 255, 255, 0.05)',
            },
          },
          x: {
            ticks: {
              color: colors.white,
              callback: (value, index, values) => {
                // Display every second date label
                if (index % 2 === 0) {
                    // Access the date property from the dateLabels array
                    return dateLabels[index];
                }
            },
            },
            grid: {
              display: false,
            },
          },
        },
    plugins: {
        title: {
        display: true,
        text: 'Average Article Tone',
        color: colors.white,
        padding: {
          bottom: 20,
        },
        },
        legend: {
            display: false,
        },
        tooltip: {
            callbacks: {
              label: function(tooltipItem, data) {
                const datasetIndex = tooltipItem.datasetIndex;
                //const index = tooltipItem.dataIndex;
                const tone = articleCounts[tooltipItem.dataIndex];
                /* const iterator = dateToMostRelevantArticlesMap.current.entries();
                const keyValueArray = Array.from(iterator, ([key, value]) => ({ key, value })); */
                return 'Avg. Tone: ' + tone; //+ ' Most relevant article: ' + (keyValueArray[index] != null ? keyValueArray[index].value : 'Not Yet evaluated');
              },
            },
        },
    },
    maintainAspectRatio: false,
    responsive: true,
    };

    const removeOldValues = () => {
        setCurrentDates([]);
        setCurrentArticleCounts([]);
    }

    return (
        <div className='timeline-class'>
            {selectedLocation === 'nocountry' ? (
                <p className='info-text'>This chart is not available for "no country".</p>
            ) : (
                <Bar data={data} options={options} />
            )}
        </div>
    );
};

export default ToneTimelineComponent;
