import React, { useState } from 'react';
import './MobileArticlesComponent.scss';
import SelectedLocationArticlesComponent from './SelectedLocationArticlesComponent.js';
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const MobileArticlesComponent = ({articles, selectedTopic, selectedTopicAlt, mentioningThreshold, timespanNumber, timespanUnit, isGodmode}) => {
    const [locationLabel, setLocationLabel] = useState('Global');
    const [selectedLocation, setSelectedLocation] = useState('global');
    const sourceCountries = articles.map(article => article.sourcecountry);
    const [sortBy, setSortBy] = useState("relevancy")

    console.log(articles);
    sourceCountries.unshift('Global');
    const uniqueSourcecountries = [...new Set(sourceCountries)].filter(country => country !== "");

    const handleSortByChange = (event) => {
      setSortBy(event.target.value);
    }

    const reset = () => {
        setLocationLabel('Global');
        setSelectedLocation('global');
    }

    const handleChangeLocation = (selectedLabel) => {
        if (selectedLabel === 'Global') {
          setSelectedLocation('global');
        } else {
          setSelectedLocation(selectedLabel.replace(/ /g, ''));
        }
    };
    
    const handleChangeLocationLabel = (event) => {
        const selectedValue = event.target.value;
        console.log("label " + selectedValue);
        setLocationLabel(selectedValue);
        handleChangeLocation(selectedValue);
    }

      return (
        <div className='mobile-articles-container'>
        <div className='mobile-side-container-label'>
            <div className='form-container'>
            <FormControl className='mobile-sort-selection'>
              <Select
                labelId="timespan"
                id="timespanSelect"
                value={sortBy}
                onChange={handleSortByChange}
                className='select-container'
              >
                <MenuItem value={"relevancy"}>Relevancy</MenuItem>
                <MenuItem value={"latest"}>Latest</MenuItem>
              </Select>
            </FormControl>
            <FormControl className='articles-selection'>
                      <Select
                        id="articleSelection"
                        value={locationLabel}
                        onChange={handleChangeLocationLabel}
                        className='select-container'
                      >
                        {uniqueSourcecountries.map((country, index) => (
                        <MenuItem key={index} value={country}>{country}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
            </div>
        </div>
        <div className='mobile-side-container-content'>
          <SelectedLocationArticlesComponent selectedTopic={selectedTopic} selectedTopicAlt={selectedTopicAlt} mentioningThreshold={mentioningThreshold} selectedLocation={selectedLocation} timespanNumber={timespanNumber} timespanUnit={timespanUnit} sortBy={sortBy}></SelectedLocationArticlesComponent>
        </div>
        <div className='reset-container'>
          <button className='reset-button' onClick={() => reset()}>Reset</button>
        </div>
      </div>
      );
      
    };



export default MobileArticlesComponent;
