import { useState, useEffect } from 'react';

const useIsMobile = (breakpoint = 1400) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIsMobile = () => {
      const mediaQuery = window.matchMedia(`(max-width: ${breakpoint}px)`);
      setIsMobile(mediaQuery.matches);
    };

    checkIsMobile();
    window.addEventListener('resize', checkIsMobile);


    return () => {
      window.removeEventListener('resize', checkIsMobile);
    };
  }, [breakpoint]); 

  return isMobile;
};

export default useIsMobile;
